<template>
	<ol class="commentlist">
		<div ref="commentslist" class="comment-tab">
			<div class="come-comt">
				<i class="fa fa-comments"></i>留言列表 
				<span id="comment_count">(已有
					<span style="color:#E1171B">{{gestbookCount}}</span>条留言)
				</span>
			</div>
		</div> 
		<div v-if="gestbookCount>0">
			<div class="shadow-box msg noimg wow" v-for="gestbookInfo,index in gestbookData" :key="index"  >
					<div class="msgimg">
						<img class="avatar" :src="gestbookInfo.userphoto" >
					</div>
					<div class="msgtxt">
						<div class="msgname">
							<a>{{gestbookInfo.username}}</a>
							<span class="LevelName aulevel6">游客</span>
							<span>{{gestbookInfo.addtime}}
								<a @click="replyComments(gestbookInfo.id)" class="comment-reply-link">回复</a>
							</span>
						</div>
						<div class="msgarticle">{{gestbookInfo.content}}
							<div :ref="'reply_'+gestbookInfo.id" style="display: none;">
								<GuestBookTool v-bind:guestbookid="gestbookInfo.id" @change-gestbooks="change"></GuestBookTool>
							</div>
							<div class="shadow-box msg noimg wow"  v-for="replyInfo,index in gestbookInfo.replyData" :key="index">
								<div class="msgimg">
									<img class="avatar" :src="replyInfo.userphoto" >
								</div>
								<div class="msgtxt">
									<div class="msgname">
										<a href=""  target="_blank">{{replyInfo.username}}</a>
										<span class="LevelName aulevel6">游客</span>
										<span>{{replyInfo.addtime}}</span>
									</div>
									<div class="msgarticle">
										{{replyInfo.content}}
									</div>
								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
		<div class="no-comments" v-else>暂无评论</div>
		<!--评论输出结束-->
	</ol>
</template>

<script>
	import GuestBookTool from "./GuestBookTool.vue";
	export default{
		props:['gestBooksData'],
		components:{
			GuestBookTool
		},
		data:function(){
			return {
				pid           : this.gestBooksData.pid,
				gestbookCount : this.gestBooksData.gestbookCount,
				gestbookData : this.gestBooksData.gestbookData
			}
		},
		mounted:function(){
			console.log(this.gestbookCount,this.pid)
		},
		methods:{
			change:function(data , guestbookid){
				this.gestbookCount = data.gestbookCount;
				this.gestbookData = data.gestbookData;
				console.log(this.commentsList);
				this.$refs['reply_'+guestbookid][0].style.display="none";
			},
			
			replyComments:function(id){
				
				for(var key in this.$refs){
					if(Array.isArray((this.$refs[key])))
						this.$refs[key][0].style.display="none";
				}
				let el =this.$refs['reply_'+id][0];
				el.style.display="block";
			}
		}
	}
</script>

<style>
</style>
