<template>
	<div class="content">
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading"  class="middle-content">
			<div class="index-content-left" style="width:100%">
				<!-- 网站源码 -->
				<div ref="code" class="content-code ">
					<div  class="rec-title new-publish animate__animated animate__fadeInDown" style="margin-top: 0;">网站源码</div>
					<div class="home-main sell-ul animate__animated animate__fadeInDown">
						<div class="slide-item sell-list" v-for="codeInfo,index in codeslist" :key="index">
							<div class="slide-box">
								<a @click="goDetail(codeInfo.id)" style="cursor: pointer;" :title="codeInfo.title">
									<div class="slide-img">
										<img class="lazy" :src="codeInfo.pic">
									</div>
									<div class="slide-body">
										<p class="slide-title">{{codeInfo.title}}</p>
										<p class="slide-info">{{codeInfo.desc}}</p>
										<p class="product-card-text">
											<span class="text-price"><i class="price-unit-text">￥</i>{{codeInfo.price}}</span>
											<span class="text-tag-warning">推荐</span>
										</p>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import Loading from "../components/Loading.vue";
	export default{
		components:{
			Loading
		},
		data:function(){
			return {
				isScroll : true,
				codeslist     : [],
				codeshow:false,
				limit:6,
				curentIndex:1,
				isLoading :true,
			}
		},
		created:function(){
			this.getCodeList(18);
		},
		mounted:function(){
			window.addEventListener("scroll", this.handleScroll);
		},
		destroyed:function(){
			window.removeEventListener('scroll',this.handleScroll)
		},
		methods:{
			goDetail:function(cid){
				this.$router.push({name:'codeinfo' , params:{id:cid+".html"}})
			},
			handleScroll:function(){	
			
				// 滚动的高度
				let scrollTop = document.documentElement.scrollTop||window.pageYOffset||document.body.scrollTop;
				// 屏幕的高度
				let clientHeight = document.documentElement.clientHeight;
				// 
				let scrollHeight = document.documentElement.scrollHeight;
				console.log(scrollHeight,scrollTop,clientHeight);
				if(scrollHeight-scrollTop-clientHeight<=55&&this.isScroll){
					this.isScroll = false;
					this.curentIndex +=1;
					this.getCodeList(this.limit)
				}	
			},
			getCodeList:function(size){
				
				let refArray = [
					{ref: 'code', show:  'codeshow'}
				]
				// 获取网站源码
				let codeData = {
						headers : {
							size     : size,
							source   : 1,
							vcode    : "11"
						}
					}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/indexcodes/?page='+this.curentIndex,codeData).then(res=>{
					if(res.data.code == 200){
						this.isLoading = false;
						if(res.data.data.length>0){
							this.isScroll = true;
							this.codeslist = this.codeslist.concat(res.data.data);
							this[refArray[0].show] = true;
						}else{
							let layerid = this.$layer.msg("没有更多数据了！！",()=>{
								this.$layer.close(layerid);
							});
						}
					}else{
						let layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}	
				})			
			}
		}
	}
</script>

<style scoped="scoped">
	@import url("../assets/css/main.css");
	.content .middle-content .index-content-left .home_shop .shop_main {
		margin: 0px 10px 20px 0;
		padding-right: 10px;
		padding-bottom: 20px;
		border-bottom: 1px solid #eee;
		border-right: 1px solid #eee;
	}
</style>
