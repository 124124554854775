<template>
	<div class="content">
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading" class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left">
				<div class="widget-box post-single">
					<article  class="widget-content single-post">
						<header id="post-header">
							<div class="post-meta">
								<span class="time">{{contentData.addtime}}</span>
								<span class="eye">
									<i class="fa fa-eye"></i>{{contentData.viewcount}}
								</span>
							</div>
							<h1 class="post-title">
								<a href="javascript:void(0)" title="正在阅读：友情链接" rel="bookmark">友情链接</a>
							</h1>
							<div class="clear"></div>
						</header>
						<div class="entry  animate__animated animate__fadeInDown">
							<div id="listree-bodys">
								<div class="entry link-entry">
									<div class="link-parent-div" style="display: none;">
										<div class="link-root-div">
											<div class="link-div">
												<span class="link-button">
													友情提示
												</span>
											</div>
											<div class="link-tishi-div">
												<p class="link-p-content">
													<span class="link-content-span">
														<strong style="color: inherit; box-sizing: inherit;">
															由于网站版面有限，对于内容较少且长期不更新的博客，即日起全部移到本页面。
														</strong>
													</span>
												</p>
												<p class="link-p-content">
													<span class="link-content-span">
														<strong style="color: inherit; box-sizing: inherit;">如您对此有异议，请撤销本站连接。</strong>
													</span>
												</p>
												<p class="link-p-content">
													本站会定期检查友链，没有本站链接的，本站会撤销链接。
													也可能存在删错的情况，如果发现没有链接了，可以再次提交，但请勿多次提交，后台需要审核，谢谢各位站长配合.
												</p>
											</div>
										</div>
									</div>
									<ul class="link-ul">
										<li v-for="linkInfo,index in linksList" :key="index">
											<a :href="linkInfo.url" target="_blank" :title="linkInfo.title">{{linkInfo.title}}</a>
										</li>
									</ul>
									<div class="clear"></div>
									<div class="link-parent-div">
										<div class="link-root-div">
											<div class="link-div" style="position: relative;">
												<span class="link-button">
													申请条件
												</span>
											</div>
											<div style="text-align: left;">
												<p class="link-p-content-one">
													1：本站只关心您的更新频率。
												</p>
												<p class="link-p-content-one">
													2：请确保您的网站内容健康，不含政治内容以及擦边球内容
												</p>
												<p class="link-p-content-one">
													3：违背道德或者违法的网站，本站概不接受交换链接
												</p>
											</div>
										</div>
									</div>
									<div class="link-parent-div">
										<div class="link-root-div">
											<div class="link-div" style="position: relative;">
												<span class="link-button">
													其他说明
												</span>
											</div>
											<div style="text-align: left;">
												<p class="link-p-content-one">
													1、本站不定期检查死链等无效链，如发现本站链接被取消，本站也将删除相应链接，恕不另行通知。
												</p>
												<p class="link-p-content-one">
													2、对于长期无更新、无法访问和缺少互动的博客网站，本站有权终止其友情链接。
												</p>
												<p class="link-p-content-one">
													3、希望在友链之后，能互勉互动，让我知道你的存在，不过在我看来友情最重要。
												</p>
												<p class="link-p-content-one">
													4、<span style="background: #59DA99; margin: 0px; padding: 0px; border: 0px currentColor; border-image: none; color: #F2F2F2; box-sizing: inherit;">请确定贵站能：长期、稳定、运营下去，再做申请！</span>
												</p>
												<p class="link-p-content-one">
													5、链接如有变更请联系告知。
												</p>
											</div>
										</div>
									</div>
								</div>
								
							</div>
							
						</div>
						<div ref="sharetool" :class="{animate__fadeInDown:shareShow}"  class="xf_zsf animate__animated">
							<LikeShareTool v-bind:likeInfo="likeData" v-if="likeData.finish" ></LikeShareTool>
						</div>
						<div ref="authorarea" :class="{animate__fadeInDown:authorShow}"  class="animate__animated">
							<AuthorArea v-bind:articleCount = "artCount"></AuthorArea>
						</div>
						<div  ref="fri-link" :class="{animate__fadeInDown:linkShow}"  class="comments animate__animated">
							<LinkTool></LinkTool>
						</div>
					</article>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	import AuthorArea from "../components/AuthorArea.vue";
	import LinkTool from "../components/LinkTool.vue";
	import LikeShareTool from "../components/LikeShareTool.vue";
	import StickySidebar from "sticky-sidebar";
	import Loading from "../components/Loading.vue";
	export default{
		components:{
			SlideBar,
			AuthorArea,
			LinkTool,
			LikeShareTool,
			Loading
		},
		data:function(){
			return {
				contentData: {},
				artCount:0,
				likeData:{relativeid:0,count:0 , type :6 , finish:false},
				linksList :[],
				shareShow:false,
				authorShow:false,
				linkShow:false,
				isLoading :true,
				refArray : [
					{ref:'sharetool' , show:'shareShow'},
					{ref:'authorarea' , show:'authorShow'},
					{ref:'fri-link' , show:'linkShow'}
				]
			}
		},
		created:function(){
			this.getInfo('3');
			this.getLinks();
			window.addEventListener("scroll", this.handleScroll);
		},
		mounted:function(){

			setTimeout(() => {
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});	
			}, 2000);
		},
		methods:{
			handleScroll:function(){
				console.log(this.$refs);
				for(let i =0 ;i<this.refArray.length;i++){
					let dom = this.$refs[this.refArray[i].ref]
					let offset = 20;
					if(dom){
						var a,b,c,d;
						d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
						a = eval(d + offset)
						b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
						c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
						if(b + c > a){
							this[this.refArray[i].show] = true;
						}
					}
				}
			},
			getInfo:function(type){
				let code = this.$md5(this.$md5(type)+this.$md5('1'));
				let artData = {
					headers : {
						type     : type,
						source   : 1,
						vcode    : code
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/systems',artData).then(res=>{
					if(res.data.code == 200){
						this.isLoading = false;
						this.contentData = res.data.data.sysData;
						this.artCount    = res.data.data.artCount;
						this.likeData.relativeid = this.contentData.id;
						this.likeData.count = this.contentData.likecount;
						this.likeData.finish = true;
					}else{
						// 如果获取数据失败，跳转到首页
						this.$router.push("/");
					}
				})
			},
			getLinks:function(){
				
				let headerData = {
					headers : {
						source : 1,
						vcode  : "11"
					}
				}
				// 获取友情链接
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/links',headerData).then(res=>{
					if(res.data.code == 200){
						this.linksList = res.data.data;
					}
				})
			}
		}
	}
</script>

<style>
	
</style>
