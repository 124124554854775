<template>
	<div class="content" v-wechat-title="yuleData.title">
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading"  class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left">	
				<div class="widget-box post-single">
					<article id="post-20574" class="widget-content single-post">
						<header id="post-header" class="animate__animated animate__fadeInDown">
							<div class="post-meta">
								<span class="time">{{yuleData.addtime}}</span>
								<span class="eye">
									<i class="fa fa-eye"></i>{{yuleData.viewcount}}
								</span>
							</div>
							<h2 class="post-title">
								<a href="#" >{{yuleData.title}}</a>
							</h2>
							<div class="clear"></div>
						</header>
						<div class="entry">
							<BaiduAdvTwo></BaiduAdvTwo>
							<p class="animate__animated animate__fadeInDown" v-html="yuleData.content" v-highlight></p>
							<div  ref="articelend"  :class="{animate__fadeInDown:artcleEndShow}" class="animate__animated" id="xf_zsf ">
								<LikeShareTool v-bind:likeInfo="likeData" v-if="likeData.finish" ></LikeShareTool>
							</div>
						</div>
						<div ref="copyright"  :class="{animate__fadeInDown:copyRightShow}" class="article_copyright animate__animated">	
							<ul class="spost_info">
								<li class="sinfo_ts">
									<strong>版权所属：</strong>
									<a href="/" title="版权所属：飞猫个人博客" target="_blank">飞猫个人博客</a>
								</li>
								<li class="sinfo_sm">
									<strong>版权声明：</strong>原创文章，转载时必须以链接形式注明原始出处及本声明。
								</li>
							</ul>
						</div>
						<div ref="author" :class="{animate__fadeInDown:authorShow}" class="animate__animated">
							<AuthorArea v-bind:articleCount = "totalCount"></AuthorArea>
						</div>
						
						<div ref="author"    class="post-navigation ">
							<div :class="{animate__fadeInDown:authorShow}" class="post-previous animate__animated">
								<span>上一篇：</span>
								<a @click="goDetail(preYule.id)" style="cursor: pointer;" v-if="preYule.id" >{{preYule.title}}</a>
								<span v-else>无上一篇了</span>
							</div>
							<div :class="{animate__fadeInDown:authorShow}" class="post-next animate__animated">
								<span>下一篇：</span>
								<a @click="goDetail(nextYule.id)" style="cursor: pointer;" v-if="nextYule.id"  >{{nextYule.title}}</a>
								<span v-else>无上一篇了</span>
							</div>
						</div>
						<div class="nav-links">
							<div class="nav-previous">
								<a href="" rel="prev">
									<span class="meta-nav-r">
										<i class="fa fa-angle-left"></i>
									</span>
								</a>
							</div>
							<div class="nav-next">
								<a href="" rel="next">
									<span class="meta-nav-l">
										<i class="fa fa-angle-right"></i>
									</span>
								</a>
							</div>
						</div>
						<section   class="comments">  
							<!--评论框-->
							<div ref="coomentss" :class="{animate__fadeInDown:commentsdata}"  class="animate__animated" >
								<CommentTool v-bind:commentData="CommentData" @change-comments="change">
								</CommentTool>
							</div>
							<!--评论框结束-->
							<div ref="commentslist" :class="{animate__fadeInDown:commentlistShow}"  class="animate__animated" >
								<Comments v-bind:commentData="CommentData" v-bind:key="CommentData.commentsCount"></Comments>
							</div>
							<!--评论输出-->
							
						</section>
					</article>
				</div>
			</div>
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	import CommentTool from "../components/CommentTool.vue";
	import Comments from "../components/Comments.vue";
	import AuthorArea from "../components/AuthorArea.vue";
	import LikeShareTool from "../components/LikeShareTool.vue";
	import Loading from "../components/Loading.vue";
	import StickySidebar from "sticky-sidebar";
	import BaiduAdvTwo from "../components/BaiduAdvTwo.vue";
	export default{
		components:{
			SlideBar,
			CommentTool,
			Comments,
			AuthorArea,
			LikeShareTool,
			Loading,
			BaiduAdvTwo
		},
		data:function(){
			// tempCommentData  type:1:文章评论 2：网站源码评论 3：开心一刻评论 4：关于我们评论  5:每周日志评论
			// likeData 点赞类型：1：网站点赞 2：文章点赞 3：源码点赞  4：开心一刻点赞 5：留言点赞 6：友情链接点赞  7：关于我们点赞  8：每周日志点赞
			return {
				id  : 0,
				totalCount : 0,
				likecount : 0,
				yuleData : {},
				preYule : {},
				nextYule : {},
				likeData:{relativeid:0,count:0 , type :4 , finish:false},
				CommentData:{relativeid:0,id:0 , type :3},
				artcleEndShow:false,
				copyRightShow:false,
				authorShow:false,
				commentsdata:false,
				commentlistShow:false,
				isLoading :true,
				refArray : [
					{ref: 'articelend', show: 'artcleEndShow'},
					{ref: 'copyright', show: 'copyRightShow'},
					{ref: 'author', show: 'authorShow'},
					{ref:'coomentss' , show:'commentsdata'},
					{ref:'commentslist' , show:'commentlistShow'}
				],
				stick:{}
			}
		},
		created:function(){
			this.id = this.$route.params.id.slice(0,-5);
			this.CommentData.relativeid = this.id;
			this.likeData.relativeid = this.id;
			this.getYuleDetail();
			this.getComments();
			window.addEventListener("scroll", this.handleScroll);
		}, 
		beforeRouteUpdate:function(to , from ,next){
			// ok方式
			if(to.params.id == undefined)
				return;
			this.id = to.params.id.slice(0,-5);

			this.totalCount = 0;
			this.likecount = 0;
			this.yuleData = {};
			this.preYule = {};
			this.nextYule = {};
			this.likeData={relativeid:this.id,count:0 , type :4 , finish:false};
			this.tempCommentData={relativeid:this.id,id:0 , type :3};
			this.artcleEndShow=false;
			this.copyRightShow=false;
			this.authorShow=false;
			this.commentsdata=false;
			this.commentlistShow=false;
			this.isLoading =true;
			if(this.id){
				this.getYuleDetail();
				this.getComments();
			}

				
			setTimeout(() => {
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});	
			}, 3000);
			next();	
		},
		mounted:function(){
			setTimeout(() => {
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});	
			}, 3000);
		},
		methods:{
			change:function(data , commentid){
				this.CommentData.commentsList  = data.comentsData;
				this.CommentData.commentsCount = data.commentsCount;
				this.$forceUpdate();
				this.stick.updateSticky();
			},
			handleScroll:function(){
				for(let i =0 ;i<this.refArray.length;i++){
					let dom = this.$refs[this.refArray[i].ref]
					let offset = 20;
					if(dom){
						var a,b,c,d;
						d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
						a = eval(d + offset)
						b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
						c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
						if(b + c > a){
							this[this.refArray[i].show] = true;
						}
					}
				}
			},
			getYuleDetail:function(){
				let yuleData = {
					headers : {
						id     : this.id,
						source : 1,
						vcode  : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/yuleinfo',yuleData).then(res=>{
					
					if(res.data.code == 200){
						this.isLoading = false;
						this.yuleData = res.data.data.yuleData;
						this.likecount = this.yuleData.likecount;
						this.likeData.count = this.likecount;
						this.likeData.finish = true;
						this.totalCount = res.data.data.totalCount;
						this.preYule = res.data.data.preYule;
						this.nextYule = res.data.data.nextYule;
					}else{
						this.isLoading = false;
						this.$router.push({name:'notfound'})
					}	
				})
			},
			goDetail:function(yuid){
				this.$router.push({name:'yuleinfo' , params:{id:yuid+".html"}})
			},
			getComments:function(){
				let comData = {
					headers : {
						relativeid  : this.CommentData.relativeid,
						type        : this.CommentData.type,
						source      : 1,
						vcode       : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/artcoms',comData).then(res=>{
					if(res.data.code == 200){
						this.CommentData.commentsList = res.data.data.comentsData;
						this.CommentData.commentsCount = res.data.data.commentsCount;
						this.stick.updateSticky();
					}else{
						let layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}	
				})
			},
		}
	}
</script>

<style>
	
</style>
