<template>
	<div ref="author"  id="authorarea">
		<div   class="authorinfo">
			<div class="author-avater">
				<img class="avaterimg"  src="../assets/images/user-logo.png" height="50" width="50">
			</div>
			<div class="author-des">
				<div class="author-meta">
					<span class="post-author-name">
						<a href="/">飞猫</a>
					</span>
					<span class="post-author-tatus">
						<a @click="goCatArt()" style="cursor: pointer;">
							<span style="color: #59DA99;">{{articleCount}}</span> 篇文章
						</a>
					</span>
					<span class="post-author-url">
						<a href="/" target="_blank">站点</a>
					</span>
				</div>
				<div id="hitokoto">
					飞猫博客，记录工作生活的点点滴滴！
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:['articleCount'],
		data:function(){
			return {
			}
		},
		created:function(){
		},
		methods:{
			goCatArt :function(){
				// ok方式
				this.$router.push({path:'/article' , query:{catid:0}})
			}
		}
	}
</script>

<style>
</style>
