<template>
	<div class="content">
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div>
			<div class="content-swiper animate__animated animate__fadeInDown">
				<swiper style="width: 100%;" ref="mySwiper" v-if="swiperList.length>0" :options="swiperOption">
					<swiper-slide  v-for="(data,index) in swiperList" :key="index">
						<img :src="data.img" />
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
		</div>
		
		<div class="clear"></div>
		<div class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left">
				<!-- 特别推荐 -->
				<div class="rec-title" v-if="recommList.length>0">精选推荐</div>
				<ul class="recom-content animate__animated animate__fadeInDown">
					<li class="rec-item" v-for="recommInfo,index in recommList" :key="index">
						<a @click="goDetail(recommInfo.id+'.html')" style="cursor: pointer;" target="_blank">
							<img :src="recommInfo.pic" :title="recommInfo.title">
							<h3>{{recommInfo.title}}</h3>
							<p class="muted">{{recommInfo.desc}}</p>
						</a>
					</li>
				</ul>
				<!-- 最新发布 -->
				<div>
					<div ref="read">
						<div  :class="{animate__fadeInDown:readshow}"  v-if="newListOne.length>0" class="rec-title new-publish animate__animated">最新发布</div>
						<article :class="{animate__fadeInDown:readshow}"  class="post_box animate__animated"  v-for="artInfo,index in newListOne" :key="index">
							<div class="post-img col-xs-4">
								<a @click="goDetail(artInfo.id+'.html')"  style="display: block;zoom:1;cursor: pointer;">
									<img class="img-responsive img-rounded imgs" :src="artInfo.pic"  :title="artInfo.title"><em></em>
								</a>
							</div>
							<div class="post-left">
								<h3>
									<a @click="goDetail(artInfo.id+'.html')" style="cursor: pointer;"  :title="artInfo.title">{{artInfo.title}}</a>
								</h3>
								<div class="post-con">
									<a @click="goDetail(artInfo.id+'.html')" style="cursor: pointer;" >{{artInfo.desc}}</a>
								</div>
								<aside class="item-meta">
									<span class="mu-ml">
										<i class="fa  iconfont icon-chuangzuozhezhongxin"></i>{{artInfo.author}}
									</span>
									<span class="mu-ml-clock">
										<i class="fa  iconfont icon-time"></i>{{artInfo.updatetime}}
									</span>
									<span class="mu-ml-eye">
										<i class="fa iconfont icon-liulanliang"></i>{{artInfo.viewcount}}
									</span>
									<span class="mu-ml-comment">
										<i class="fa iconfont icon-pinglun1"></i>{{artInfo.commentcount}}
									</span>
								</aside>
							</div>
						</article>
					</div>
					<div ref="read1">
						<article :class="{animate__fadeInDown:readshow1}"  class="post_box animate__animated"  v-for="artInfo,index in newListTwo" :key="index">
							<div class="post-img col-xs-4">
								<a @click="goDetail(artInfo.id+'.html')"  style="display: block;zoom:1;cursor: pointer;">
									<img class="img-responsive img-rounded imgs" :src="artInfo.pic"  :title="artInfo.title"><em></em>
								</a>
							</div>
							<div class="post-left">
								<h3>
									<a @click="goDetail(artInfo.id+'.html')"  :title="artInfo.title">{{artInfo.title}}</a>
								</h3>
								<div class="post-con">
									<a @click="goDetail(artInfo.id+'.html')" >{{artInfo.desc}}</a>
								</div>
								<aside class="item-meta">
									<span class="mu-ml">
										<i class="fa iconfont icon-chuangzuozhezhongxin"></i>{{artInfo.author}}
									</span>
									<span class="mu-ml-clock">
										<i class="fa iconfont icon-time"></i>{{artInfo.updatetime}}
									</span>
									<span class="mu-ml-eye">
										<i class="fa iconfont icon-liulanliang"></i>{{artInfo.viewcount}}
									</span>
									<span class="mu-ml-comment">
										<i class="fa iconfont icon-pinglun1"></i>{{artInfo.commentcount}}
									</span>
								</aside>
							</div>
						</article>
					</div>
				</div>
				<!-- 网站源码 -->
				<div ref="code" class="content-code ">
					<div :class="{animate__fadeInDown:codeshow}" v-if="codeslist.length>0" class="rec-title new-publish  animate__animated   ">网站源码</div>
					<section  :class="{animate__fadeInDown:codeshow}" class="home_shop shadow-box wow animate__animated " >
						<ul>
							<li class="shop_main" v-for="codeInfo,index in codeslist" :key="index">
								<a @click="codeDetail(codeInfo.id+'.html')" style="cursor: pointer;" :title="codeInfo.title"  class="shopthumb">
									<img :src="codeInfo.pic" style="display: block;">
								</a>
								<h3>
									<a @click="codeDetail(codeInfo.id+'.html')" style="cursor: pointer;" :title="codeInfo.title">{{codeInfo.title}}</a>
								</h3>
								<p>{{codeInfo.desc}}</p>
								<div class="shop_btn">
									<span class="price">{{codeInfo.updatetime}}</span>
									<a class="zzxq" @click="codeDetail(codeInfo.id+'.html')" style="cursor: pointer;">
										<i class="fa fa-chain"></i>查看详情
									</a>
								</div>
							</li>
						</ul>
					</section>
				</div>
				<!-- 开心一刻   日志-->
				<div ref="rizhi" class="cms">
					<!-- 开心一刻 -->
					<article :class="{animate__fadeInDown:rizhishow}" class="cms-cate shadow-box wow animate__animated" >
						<div class="cms-main">
							<h3 class="cms-title">
								<i class="fa fa-bars  iconfont icon-fenlei"></i>
								<a href="/yule" rel="category">开心一刻</a>
								<a class="cat-angle" href="#" rel="category">
									<i class="fa fa-angle-right"></i>
								</a>
							</h3>
							<div class="clear"></div>
							<div class="cms-post">
								<ul class="cms-grid">
									<li class="list-title" v-for="yuleInfo,index in yuleList" :key="index">
										<a class= "title" @click="yuleDetail(yuleInfo.id+'.html')" style="cursor: pointer;" :title="yuleInfo.title">{{yuleInfo.title}}</a>
										<span class="addtime">{{yuleInfo.addtime1}}</span>
									</li>
								</ul>
							</div>
						</div>
					</article>
					<!-- 日志 -->
					<article  :class="{animate__fadeInDown:rizhishow}" class="cms-cate shadow-box wow animate__animated">
						<div class="cms-main"  style="min-height: 279px;">
							<h3 class="cms-title">
								<i class="fa fa-bars iconfont icon-fenlei"></i>
								<a href="/note" rel="category">每周日志</a>
								<a class="cat-angle" href="" rel="category">
									<i class="fa fa-angle-right"></i>
								</a>
							</h3>
							<div class="clear"></div>
							<div class="cms-post">
								<ul class="cms-grid">
									<li class="list-title" v-for="noteInfo,index in noteList" :key="index">
										<a class= "title" @click="noteDetail(noteInfo.id+'.html')" style="cursor: pointer;" :title="noteInfo.title">{{noteInfo.title}}</a>
										<span class="addtime">{{noteInfo.addtime1}}</span>
									</li>   
								</ul>
							</div>
						</div>
					</article>
				</div>
			</div>
			<div class="clear"></div>
			<!-- <div ref="keji" id="mxlee-main" style="display: none;" class="wow ">
				<h3 :class="{animate__fadeInDown:kejishow}" class="mx-showcase-title showcase-title animate__animated">
					<i class="icon-left fa fa-th-large"></i>
					<a href="https://www.86719.cn/technews/" rel="category">科技前沿</a>
					<a class="mx3-cat-angle" href="https://www.86719.cn/technews/" rel="category">
						<i class="fa fa-angle-right"></i>
					</a>
				</h3>
				<div :class="{animate__fadeInDown:kejishow}" class="mx-cat-g3 shadow-box animate__animated">
					<article class="mx3-hentry nba">
						<figure class="mx3-thumbnail">
							<a href="https://www.86719.cn/post/426.html">
								<img class="lazy" src="http://localhost/dkblog/public/uploads/banner/images/20200510/dcbbad4344ac8bd3e9fc22fbb9e7d8ed.jpg" alt="2020再见，2021您好！未来的日子我们继续风雨同舟。" original="https://www.86719.cn/zb_users/upload/2020/12/202012311609376830137073.jpg" style="display: block;">
							</a>
						</figure>
						<header class="mx3-header">
							<h2 class="mx3-entry-title"><a href="https://www.86719.cn/post/426.html" rel="bookmark">2020再见，2021您好！未来的日子我们继续风雨同舟。</a></h2>
						</header>
						<div class="mx3-entry-content">
							<span class="mx3-entry-meta">
								<span class="mx3-date"><i class="fa fa-calendar"></i> 12/31</span>
								<span class="mx3-views"><i class="fa fa-paw"></i> 182</span>
								<span class="mx3-comment">
									<a href="https://www.86719.cn/post/426.html#comments" rel="mx3-external-nofollow"><i class="fa fa-comment"></i> 0</a>
								</span>
							</span>
							<div class="clear"></div>
						</div>
					</article>
					<article class="mx3-hentry nba">
						<figure class="mx3-thumbnail">
							<a href="https://www.86719.cn/post/422.html">
								<img class="lazy" src="http://localhost/dkblog/public/uploads/banner/images/20200510/dcbbad4344ac8bd3e9fc22fbb9e7d8ed.jpg" alt="网贷或信用卡逾期，遇到违规催收，教你如何巧妙应对。" original="https://www.86719.cn/zb_users/upload/2020/12/202012111607677002310570.jpg" style="display: block;">
							</a>
						</figure>
						<header class="mx3-header">
							<h2 class="mx3-entry-title"><a href="https://www.86719.cn/post/422.html" rel="bookmark">网贷或信用卡逾期，遇到违规催收，教你如何巧妙应对。</a></h2>
						</header>
						<div class="mx3-entry-content">
							<span class="mx3-entry-meta">
								<span class="mx3-date"><i class="fa fa-calendar"></i> 12/11</span>
								<span class="mx3-views"><i class="fa fa-paw"></i> 205</span>
								<span class="mx3-comment">
									<a href="https://www.86719.cn/post/422.html#comments" rel="mx3-external-nofollow"><i class="fa fa-comment"></i> 0</a>
								</span>
							</span>
							<div class="clear"></div>
						</div>
					</article>
					<article class="mx3-hentry nba">
						<figure class="mx3-thumbnail">
							<a href="https://www.86719.cn/post/413.html">
								<img class="lazy" src="http://localhost/dkblog/public/uploads/banner/images/20200510/dcbbad4344ac8bd3e9fc22fbb9e7d8ed.jpg" alt="微信转账交易记录如何取证？最新方法来了，赶紧收藏加转发吧。" original="https://www.86719.cn/zb_users/upload/2020/11/202011051604538482587228.jpg" style="display: block;">
							</a>
						</figure>
						<header class="mx3-header">
							<h2 class="mx3-entry-title"><a href="https://www.86719.cn/post/413.html" rel="bookmark">微信转账交易记录如何取证？最新方法来了，赶紧收藏加转发吧。</a></h2>
						</header>
						<div class="mx3-entry-content">
							<span class="mx3-entry-meta">
								<span class="mx3-date"><i class="fa fa-calendar"></i> 11/05</span>
								<span class="mx3-views"><i class="fa fa-paw"></i> 220</span>
								<span class="mx3-comment">
									<a href="https://www.86719.cn/post/413.html#comments" rel="mx3-external-nofollow"><i class="fa fa-comment"></i> 0</a>
								</span>
							</span>
							<div class="clear"></div>
						</div>
					</article>
					<article class="mx3-hentry nba">
						<figure class="mx3-thumbnail">
							<a href="https://www.86719.cn/post/405.html">
								<img class="lazy" src="http://localhost/dkblog/public/uploads/banner/images/20200510/dcbbad4344ac8bd3e9fc22fbb9e7d8ed.jpg" alt="共建网络安全，共享网络文明，网络安全人人有责。" original="https://www.86719.cn/zb_users/upload/2020/09/202009141600062601367068.jpg" style="display: block;">
							</a>
						</figure>
						<header class="mx3-header">
							<h2 class="mx3-entry-title"><a href="https://www.86719.cn/post/405.html" rel="bookmark">共建网络安全，共享网络文明，网络安全人人有责。</a></h2>
						</header>
						<div class="mx3-entry-content">
							<span class="mx3-entry-meta">
								<span class="mx3-date"><i class="fa fa-calendar"></i> 09/14</span>
								<span class="mx3-views"><i class="fa fa-paw"></i> 339</span>
								<span class="mx3-comment">
									<a href="https://www.86719.cn/post/405.html#comments" rel="mx3-external-nofollow"><i class="fa fa-comment"></i> 0</a>
								</span>
							</span>
							<div class="clear"></div>
						</div>
					</article>
					<article class="mx3-hentry nba">
						<figure class="mx3-thumbnail">
							<a href="https://www.86719.cn/post/399.html">
								<img class="lazy" src="http://localhost/dkblog/public/uploads/banner/images/20200510/dcbbad4344ac8bd3e9fc22fbb9e7d8ed.jpg" alt="五大行同日公告8月25日起, 个人房贷将统一转换为LPR定价。" original="https://www.86719.cn/zb_users/upload/2020/08/202008131597282500468423.jpg" style="display: block;">
							</a>
						</figure>
						<header class="mx3-header">
							<h2 class="mx3-entry-title"><a href="https://www.86719.cn/post/399.html" rel="bookmark">五大行同日公告8月25日起, 个人房贷将统一转换为LPR定价。</a></h2>
						</header>
						<div class="mx3-entry-content">
							<span class="mx3-entry-meta">
								<span class="mx3-date"><i class="fa fa-calendar"></i> 08/13</span>
								<span class="mx3-views"><i class="fa fa-paw"></i> 325</span>
								<span class="mx3-comment">
									<a href="https://www.86719.cn/post/399.html#comments" rel="mx3-external-nofollow"><i class="fa fa-comment"></i> 0</a>
								</span>
							</span>
							<div class="clear"></div>
						</div>
					</article>
					<article class="mx3-hentry nba">
						<figure class="mx3-thumbnail">
							<a href="https://www.86719.cn/post/393.html">
								<img class="lazy" src="http://localhost/dkblog/public/uploads/banner/images/20200510/dcbbad4344ac8bd3e9fc22fbb9e7d8ed.jpg" alt="支付宝蚂蚁花呗一旦逾期，会和征信挂钩，难道说“好日子”到头了？" original="https://www.86719.cn/zb_users/upload/2020/07/202007291596007945685821.jpg" style="display: block;">
							</a>
						</figure>
						<header class="mx3-header">
							<h2 class="mx3-entry-title"><a href="https://www.86719.cn/post/393.html" rel="bookmark">支付宝蚂蚁花呗一旦逾期，会和征信挂钩，难道说“好日子”到头了？</a></h2>
						</header>
						<div class="mx3-entry-content">
							<span class="mx3-entry-meta">
								<span class="mx3-date"><i class="fa fa-calendar"></i> 07/29</span>
								<span class="mx3-views"><i class="fa fa-paw"></i> 440</span>
								<span class="mx3-comment">
									<a href="https://www.86719.cn/post/393.html#comments" rel="mx3-external-nofollow"><i class="fa fa-comment"></i> 4</a>
								</span>
							</span>
							<div class="clear"></div>
						</div>
					</article>
				</div>
			</div>
			<div class="clear"></div> -->
			<div ref = "links" style="margin-top: 6px;" :class="{animate__fadeInDown:linksshow}" class="container-fluid yidong links wow animate__animated">
				<Link></Link>
			</div>
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import { swiper, swiperSlide } from "vue-awesome-swiper";
	import SlideBar from "../components/SlideBar.vue";
	import Link from "../components/Link.vue";
	import 'swiper/css/swiper.css';
	import Loading from "../components/Loading.vue";
	export default{
		components:{
			swiper,
			swiperSlide,
			SlideBar,
			Link,
			Loading
		},
		data:function(){
			return {
				swiperOption: {
					direction:"horizontal",/*横向滑动*/
					pagination: { el: '.swiper-pagination' },
					observer:true,//修改swiper自己或子元素时，自动初始化swiper
					observeParents:true,
					loop: true,
					autoplay: {
						delay: 1000,
						disableOnInteraction: false,//操作swiper后不会停止播放
					}
				},
				swiperList:[],
				
				recommList : [],
				newListOne   : [],
				newListTwo   : [],
				codeslist     : [],
				yuleList      : [],
				noteList      : [],
				readshow:false,
				readshow1:false,
				codeshow:false,
				rizhishow:false,
				remenshow:false,
				kejishow:false,
				linksshow:false,
				isLoading :true
			}
		},
		mounted:function(){
			let headerData = {
					headers : {
						source : 1,
						vcode  : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/banners',headerData).then(res=>{
				if(res.data.code == 200){
					this.swiperList = res.data.data;
					
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
			// 获取精选推荐
			let recommData = {
					headers : {
						size     : 6,
						readsize : 8,
						source   : 1,
						vcode    : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/recomm',recommData).then(res=>{
				if(res.data.code == 200){
					this.recommList = res.data.data.recommData;
					let newDataList = res.data.data.newData;
					let maxLenth =newDataList.length/2;
					for(var i=0 ;i< newDataList.length; i++){
						if(i<maxLenth)
							this.newListOne.push(newDataList[i]);
						else
							this.newListTwo.push(newDataList[i]);
					}
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
			// 获取网站源码
			let codeData = {
					headers : {
						size     : 3,
						source   : 1,
						vcode    : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/indexcodes',codeData).then(res=>{
				if(res.data.code == 200){
					this.isLoading = false;
					this.codeslist = res.data.data;
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
			this.getYuleList();
			this.getNoteList();
			window.addEventListener("scroll", this.handleScroll);
		},
		methods:{
			goDetail:function(artid){
				this.$router.push({name:'artdetail' , params:{id:artid}})
			},
			codeDetail:function(cid){
				this.$router.push({name:'codeinfo' , params:{id:cid}})
			},
			yuleDetail:function(yid){
				this.$router.push({name:'yuleinfo' , params:{id:yid}})
			},
			noteDetail:function(nid){
				this.$router.push({name:'noteinfo' , params:{id:nid}})
			},
			getYuleList:function(){
				// 获取精选推荐
				let yuleData = {
					headers : {
						size     : 6,
						source   : 1,
						vcode    : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/yules',yuleData).then(res=>{					
					if(res.data.code == 200){
						this.yuleList = res.data.data.yuleData;
					}
				})
			},
			getNoteList:function(){
				// 获取精选推荐
				let noteData = {
					headers : {
						size     : 6,
						source   : 1,
						vcode    : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/notelists',noteData).then(res=>{					
					if(res.data.code == 200){
						this.noteList = res.data.data.notesData;
					}
				})
			},
			handleScroll:function(){
				let refArray = [
					{ref: 'read', show: 'readshow'},
					{ref: 'read1', show: 'readshow1'},
					{ref: 'code', show:  'codeshow'},
					{ref: 'rizhi', show: 'rizhishow'},
					{ref: 'remen', show: 'remenshow'},
					{ref: 'keji', show: 'kejishow'},
					{ref: 'links', show: 'linksshow'},
				]
				for(let i =0 ;i<refArray.length;i++){
					let dom = this.$refs[refArray[i].ref]
					let offset = 20;
					if(dom){
						var a,b,c,d;
						d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
						a = eval(d + offset)
						b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
						c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
						if(b + c > a){
							this[refArray[i].show] = true;
						}
					}
				}
			}
		}
	}
</script>

<style>
	@import url("../assets/css/main.css");

</style>
