<template>
	<div id="comt-respond" class="commentpost wow" >
		<h4>
			<i class="fa fa-pencil"></i>申请友链
		</h4>
		<div id="frmSumbit" target="_self" method="post">
			<div class="comt-box">
				<div class="form-group liuyan form-name">
					<input type="text" id="inpName" class="text" name="inpName" v-model="title"  placeholder="标题">
				</div>
				<div class="form-group liuyan form-email">
					<input type="text" id="inpEmail" class="text" name="inpEmail"  v-model="email" placeholder="邮箱">
				</div>
				<div class="form-group liuyan form-www">
					<input type="text" id="inpHomePage" name="inpHomePage" class="text" v-model="url"  placeholder="网址">
				</div>
			</div>
			<!--verify-->
			<div id="comment-tools">
				<span class="com-title">快捷回复：</span>
				<a title="粗体字" onmousedown="InsertText(objActive,ReplaceText(objActive,'[B]','[/B]'),true);">
					<i class="fa fa-bold"></i>
				</a>
				<a title="斜体字" onmousedown="InsertText(objActive,ReplaceText(objActive,'[I]','[/I]'),true);">
					<i class="fa fa-italic"></i>
				</a>
				<a title="下划线" onmousedown="InsertText(objActive,ReplaceText(objActive,'[U]','[/U]'),true);">
					<i class="fa fa-underline"></i>
				</a>
				<a title="删除线" onmousedown="InsertText(objActive,ReplaceText(objActive,'[S]','[/S]'),true);">
					<i class="fa fa-strikethrough"></i>
				</a>
				<a href="javascript:addNumber('文章不错,写的很好！')" title="文章不错,写的很好！">
					<i class="fa fa-thumbs-o-up"></i>
				</a>
				<a href="javascript:addNumber('我踩！我踩踩踩')" title="我踩！我踩踩踩">
					<i class="fa fa-thumbs-o-down"></i>
				</a>
				<a href="javascript:addNumber('支持！！继续努力')" title="支持！！继续努力">
					<i class="fa fa-heart"></i>
				</a>
			</div>
			<textarea v-model="link_desc" placeholder="和谐网络 文明上网 理性发言！O(∩_∩)O~~" name="txaArticle" id="txaArticle" class="text input-block-level comt-area" cols="50" rows="4" tabindex="5" onkeydown="if(event.ctrlKey&amp;&amp;event.keyCode==13){document.getElementById('submit').click();return false};"></textarea>
			<p>
				<input name="sumbit" type="button" value="提交" @click="applyLink()" class="button">
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		props:['guestbookid'],
		data:function(){
			return {
				title      : '',
				email      : '',
				url        : '',
				link_desc  : ''
			}
		},

		methods:{
			
			applyLink:function(){
				let data = {
						title        : this.title, 
						email        : this.email,
						url          : this.url,
						link_desc    : this.link_desc,
						source       : 1,
						vcode        : "11"
				};
				let param = this.qs.stringify(data);
				
				this.axios.post(process.env.VUE_APP_API_PREFIEX+"/api/links",param).then(res=>{
					var layerid ; 
					if(res.data.code == '200'){
						layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
							this.title = '';
							this.email = '';
							this.url = '';
							this.link_desc = '';
						});
					}else{
						layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}
				});
			}
		}
	}
</script>

<style>
</style>
