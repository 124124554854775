<template>
	<div class="header">
		<div class="header-top">
			<div class="header-top-center">
				<span class="title-hi">Hi, {{hoursTip}}！今天是：{{time}}</span>
				<span class="title-welcome">您好，欢迎来到飞猫个人博客!</span>
			</div>
			<div class="clear"></div>
		</div>
		<div class="header-bottom">
			<div class="header-bottom-content">
				<div class="bottom-content">
					<img  class="mobile-home" src="../assets/images/mobile_index.png" />
				</div>
				<div class="bottom-content">
					<img class="logo" src="../assets/images/cat.png" />
				</div>
				<div class="clear"></div>
				<NavBar></NavBar>
			</div>
		</div>
	</div>
</template>

<script>
	import NavBar from "./NavBar.vue";
	export default {
		components:{
			NavBar
		},
		data:function(){
			return {
				time:''
			}
		},
		created:function(){
			var myDate = new Date();
			var year = myDate.getFullYear();    //获取完整的年份(4位,1970-????)
			var month = myDate.getMonth();       //获取当前月份(0-11,0代表1月)
			var day = myDate.getDate();        //获取当前日(1-31)
			var days = myDate.getDay();                   //获取当前星期X(0-6,0代表星期天)
			this.time = year+"年"+(month+1)+"月"+day+"日  "+this.getWeeks(days);
			if(myDate.getHours()>=0&&myDate.getHours()<12){
				this.hoursTip="上午好";
			}else if(myDate.getHours()>=12&&myDate.getHours()<18){
				this.hoursTip="下午好";
			}else{
				this.hoursTip="晚上好";
			}
		},
		methods:{
			getWeeks:function(day){
				var dayStr = '';
				switch(day){
					case 0:
						dayStr = "星期日";
						break;
					case 1:
						dayStr = "星期一";
						break;
					case 2:
						dayStr = "星期二";
						break;
					case 3:
						dayStr = "星期三";
						break;
					case 4:
						dayStr = "星期四";
						break;
					case 5:
						dayStr = "星期五";
						break;
					case 6:
						dayStr = "星期六";
						break;
				}
				return dayStr;
			}
		}
	}
</script>

<style>
	@import url("../assets/css/base.css");
	@import url("../assets/css/header.css");
</style>
