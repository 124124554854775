<template>
  <div id="app">
	<Header></Header>

	<router-view></router-view>
	<Footer></Footer>
	<Backtop></Backtop>
  </div>
</template>

<script>
	import Header from "./components/Header.vue";

	import Footer from "./components/Footer.vue";
	
	import Backtop from "./components/Backtop.vue";
	export default {
		components:{
			Header,
			Footer,
			Backtop
		},
		created:function(){
			// this.$nextTick(() => {
			// 	// 禁用右键
			// 	document.oncontextmenu = new Function("event.returnValue=false");
			// 	// 禁用选择
			// 	document.onselectstart = new Function("event.returnValue=false");
			// });
			// 多条广告如下脚本只需引入一次
			// 百度联盟
			var adv = document.createElement("script");
			adv.src = "//cpro.baidustatic.com/cpro/ui/cm.js";
			var s1 = document.getElementsByTagName("script")[0]; 
			s1.parentNode.insertBefore(adv, s1);
			
			
			// 百度统计
			var _hmt = _hmt || [];
			var hm = document.createElement("script");
			hm.src = "https://hm.baidu.com/hm.js?bf2560f078a7f9029e55aff3583fa45c";
			var s = document.getElementsByTagName("script")[0]; 
			s.parentNode.insertBefore(hm, s);
		},
		mounted:function(){
		}
	}
</script>
<style>
	@import url("assets/css/base.css");
	@import url("./assets/css/fonts/iconfont.css");
</style>
