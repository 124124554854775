<template>
	<div class="content">
		<div class="container-fluid home-fluid home-404">
			<div class="site-content primary error">
				<div class="content4 widget-box">
					<header id="post-header">
						<h1 class="post-title">
							<a href="/" title="Error:404-您访问的页面不存在或已删除！" rel="bookmark">Error:404-您访问的页面不存在或已删除！</a>
						</h1>
					</header>
					<div class="error_content">
						<div class="error_left">
						</div>
						<div class="error_right">
							<div class="error_detail">
								<p class="error_p_title">妈呀~ 服务器居然累坏了!</p>
								<p class="error_p_con">● 别着急哈，小猫正在加班处理，马上就好了。</p>
								<p class="error_p_con">● 您可联系站长微信，通知小猫~~!</p>
								<p class="error_p_con">● 您的支持是飞猫最大的动力,感谢您对小猫的支持,请您耐心等待!</p>
							</div>
							<div class="btn_error">
								<a class="btn_back1" href="http://icomplaints.cn/">返回首页</a>
								<a class="btn_back2" href="javascript:history.go(-1);">返回上一页</a>
							</div>
						</div>
						<div class="clear"></div>
						<div id="related-posts" class="related-posts">
							<h3>随便看看吧</h3>
							<ul class="widget-content">
								<li class="related-item post-thumbnail" v-for="info,index in recentlist" :key="index">
									<a href="" :title="info.title" >
										<img :src="info.pic" :alt="info.title" width="330" height="200">
										<h5>{{info.title}}</h5>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</div>
</template>

<script>
	export default{
		data:function(){
			return {
				recentlist    : []
			}
		},
		mounted:function(){
			
			let recentData = {
					headers : {
						size   : 4,
						source : 1,
						vcode  : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/recents',recentData).then(res=>{
				if(res.data.code == 200){
					this.recentlist = res.data.data;
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
		},
		methods:{
			goDetail:function(artid){
				this.$router.push({name:'artdetail' , params:{id:artid}})
			}
		}
	}
</script>

<style>
</style>
