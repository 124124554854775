<template>
	<div class="content">
		<div class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left">
				<article class="post_box" id="post_box1">
					<div class="post-img col-xs-4">
						<a href="https://www.86719.cn/post/448.html" style="display: block;zoom:1;">
							<img class="img-responsive img-rounded imgs" src="https://www.86719.cn/zb_users/upload/2021/06/202106071623026051894312.jpg" alt="寿命长短，不是因为衰老和生病，而是取决于生活方式！" title="详细阅读：寿命长短，不是因为衰老和生病，而是取决于生活方式！" style="display: block;" original="https://www.86719.cn/zb_users/upload/2021/06/202106071623026051894312.jpg"><em></em>
						</a>
					</div>
					<div class="post-left">
						<h3>
							<a href="https://www.86719.cn/post/448.html" title="寿命长短，不是因为衰老和生病，而是取决于生活方式！">寿命长短，不是因为衰老和生病，而是取决于生活方式！</a>
						</h3>
						<div class="post-con">
							<a href="https://www.86719.cn/post/448.html" title="查看《寿命长短，不是因为衰老和生病，而是取决于生活方式！》全文">中华医学会会长、中国工程院院士钟南山表明：虽然多项研究表明人的自然寿命能达到100岁以上，但因为种种原因，大多数人都活不到这个岁数。人体健康有五大决定因素：父母遗传占15%，社会环境占10%，自然环境占7%，医疗条件占8%，而生活方式占60%，几乎起了决定作用。1、生活作息不熬夜，晚上11：00之前...</a>
						</div>
						<aside class="item-meta">
							<span class="mu-ml">
								<i class="fa fa-list-alt"></i>百科常识
							</span>
							<span class="mu-ml-clock">
								<i class="fa fa-calendar"></i>2021-06-07
							</span>
							<span class="mu-ml-eye">
								<i class="fa fa-paw"></i>27次浏览
							</span>
							<span class="mu-ml-comment">
								<i class="fa fa-comment"></i>0条评论
							</span>
							<a class="read-more right" href="https://www.86719.cn/post/448.html" title="详细阅读：寿命长短，不是因为衰老和生病，而是取决于生活方式！">阅读全文</a>
						</aside>
					</div>
				</article>
			</div>
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	export default {
		components:{
			SlideBar,
		}
	}
</script>

<style>
	@media screen and (max-width:840px){
		.post_box {
			overflow: hidden;
			list-style: none;
			border: 1px solid #eaeaea;
			margin-bottom: 10px;
			background-color: #fff;
			position: relative;
			box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
			transition: all .3s ease-out 0s;
			-webkit-tap-highlight-color: rgba(255,0,0,0);
		}
		.post_box:hover {
			margin-left: -6px;
			background-color: #fafff8;
		}
		.post_box .post-img {
			margin: 12px 12px 5px 12px;
			padding-top: 0;
			text-align: center;
			height: 100%;
		}
		
		.col-xs-4 {
			float: left;
		}
		.post_box .post-img .imgs {
			width: 120px;
			height: 80px;
			border-radius: 6px;
		}
		.post_box .post-img a {
			position: relative;
			display: inline-block;
		}
		.post_box .post-left {
			margin: 18px 0 0 0;
			width: auto;
			display: block;
		}
		.post_box .post-left h3 {
			height: 24px;
			line-height: 20px;
			font-size: 14px;
			font-size: 1.1em;
			margin-bottom: 1px;
			padding-left: 0px;
			left: 0px;
			position: relative;
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
		}
		.post_box .post-left h3 a{
			font-size: 12px;
		}
		.post_box .post-con {
			height: 44px;
			line-height: 22px;
			margin-right: 8px;
			padding-top: 3px;
			overflow: hidden;
			font-size: 12px;
			color: #999;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			font-size: 11px;
		}
		.post_box .item-meta {
			color: #999;
			font-size: 12px;
			margin-top: 8px;
			position: relative;
			display: none;
		}

	}
	@media screen and (min-width:841px) and (max-width:1100px){
		.post_box {
			overflow: hidden;
			list-style: none;
			border: 1px solid #eaeaea;
			margin-bottom: 10px;
			background-color: #fff;
			position: relative;
			box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
			transition: all .3s ease-out 0s;
			-webkit-tap-highlight-color: rgba(255,0,0,0);
		}
		.post_box:hover {
			margin-left: -6px;
			background-color: #fafff8;
		}
		.post_box .post-img {
			margin: 12px 12px 5px 12px;
			padding-top: 0;
			text-align: center;
			height: 100%;
		}
		
		.col-xs-4 {
			float: left;
		}
		.post_box .post-img .imgs {
			width: 180px;
			height: 120px;
			border-radius: 6px;
		}
		.post_box .post-img a {
			position: relative;
			display: inline-block;
		}
		.post_box .post-left {
			margin: 18px 0 0 0;
			width: auto;
			display: block;
		}
		.post_box .post-left h3 {
			height: 24px;
			line-height: 20px;
			font-size: 14px;
			font-size: 1.1em;
			margin-bottom: 1px;
			padding-left: 0px;
			left: 0px;
			position: relative;
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
		}
		.post_box .post-left h3 a{
			font-size: 14px;
		}
		.post_box .post-con {
			height: 44px;
			line-height: 22px;
			margin-right: 8px;
			padding-top: 5px;
			overflow: hidden;
			color: #999;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			font-size: 13px;
		}
		.post_box .item-meta {
			color: #999;
			font-size: 12px;
			margin-top: 8px;
			position: relative;
		}
		.post_box .item-meta span {
			padding-right: 15px;
			white-space: nowrap;
			max-width: 35%;
			overflow: hidden;
			display: inline-block;
		}
		.post_box .item-meta span i, .multiinfo i.fa {
			padding-right: 5px;
			font-size: 1.2em;
		}
		.fa {
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
		.post_box .item-meta span {
			padding-right: 15px;
			white-space: nowrap;
			max-width: 35%;
			overflow: hidden;
			display: inline-block;
		}
		.post_box .item-meta .mu-ml-eye, .post_box .item-meta .mu-ml-comment , .post_box .item-meta .read-more{
			display: none;
		}

	}
	@media screen and (min-width:1101px){
		.post_box {
			overflow: hidden;
			list-style: none;
			border: 1px solid #eaeaea;
			margin-bottom: 10px;
			background-color: #fff;
			position: relative;
			box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
			transition: all .3s ease-out 0s;
			-webkit-tap-highlight-color: rgba(255,0,0,0);
		}
		.post_box:hover {
			margin-left: -6px;
			background-color: #fafff8;
		}
		.post_box .post-img {
			margin: 12px 12px 5px 12px;
			padding-top: 0;
			text-align: center;
			height: 100%;
		}
		
		.col-xs-4 {
			float: left;
		}
		.post_box .post-img .imgs {
			width: 180px;
			height: 120px;
			border-radius: 6px;
		}
		.post_box .post-img a {
			position: relative;
			display: inline-block;
		}
		.post_box .post-left {
			margin: 18px 0 0 0;
			width: auto;
			display: block;
		}
		.post_box .post-left h3 {
			height: 24px;
			line-height: 20px;
			font-size: 14px;
			font-size: 1.1em;
			margin-bottom: 1px;
			padding-left: 0px;
			left: 0px;
			position: relative;
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
		}
		
		.post_box .post-left h3 a{
			font-size: 13px;
		}
		.post_box .post-con {
			height: 44px;
			line-height: 22px;
			margin-right: 8px;
			padding-top: 5px;
			overflow: hidden;
			color: #999;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			font-size: 13px;
		}
		.post_box .item-meta {
			color: #999;
			font-size: 12px;
			margin-top: 8px;
			position: relative;
		}
		.post_box .item-meta span {
			padding-right: 15px;
			white-space: nowrap;
			max-width: 35%;
			overflow: hidden;
			display: inline-block;
		}
		.post_box .item-meta span i, .multiinfo i.fa {
			padding-right: 5px;
			font-size: 1.2em;
		}
		.fa {
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
		.post_box .item-meta span {
			padding-right: 15px;
			white-space: nowrap;
			max-width: 35%;
			overflow: hidden;
			display: inline-block;
		}
		a.read-more.right {
			display: block;
			position: absolute;
			background-color: #59DA99;
			padding: 2px 10px;
			color: #fff;
			overflow: hidden;
			z-index: 10;
			bottom: 0px;
			right: 12px;
			-webkit-transition: all .3s ease-out 0s;
			-o-transition: all .3s ease-out 0s;
			transition: all .3s ease-out 0s;
			border-radius: 2px;
		}
	}
</style>
