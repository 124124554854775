<template>
	<div class="content" >
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading" class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left" ref="yule">	
				<div  class="rec-title new-publish animate__animated animate__fadeInDown" style="margin-top: 0;">开心一刻</div>
				<ul  class="say-list animate__animated animate__fadeInDown">
					
					<li  class="say-item" v-for="yuleInfo,index in yuleList" :key="index">
						<p @click="goDetail(yuleInfo.id)" style="cursor: pointer;" class="info">
							{{yuleInfo.title}}
						</p>
						<p  class="date-time">{{yuleInfo.addtime}}</p>
					</li>
				</ul>
			</div>
			<!-- <div class="clear"></div> -->
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	import StickySidebar from "sticky-sidebar";
	import Loading from "../components/Loading.vue";
	export default{
		components:{
			SlideBar,
			Loading
		},
		data:function(){
			return {
				yuleList:[],
				isScroll : true,
				isLoading :true,
				limit:25,
				curentIndex:1,
				yuleshow:false,
				pagecount:0,
				stick:{}
			}
		},
		created:function(){
			this.getYuleList()
			window.addEventListener("scroll", this.handleScroll);
		},
		mounted:function(){
			setTimeout(() => {
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});	
			}, 2000);
			
		},
		destroyed:function(){
			window.removeEventListener('scroll',this.handleScroll)
		},
		methods:{
			getYuleList:function(){
				let refArray = [
					{ref: 'yule', show: 'yuleshow'},
				]
				// 获取精选推荐
				let yuleData = {
					headers : {
						size     : this.limit,
						source   : 1,
						vcode    : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/yules?page='+this.curentIndex,yuleData).then(res=>{					
					if(res.data.code == 200){
						this.isLoading = false;
						this.pagecount = res.data.data.pagecount;
						if(res.data.data.yuleData.length>0){
							this.yuleList = this.yuleList.concat(res.data.data.yuleData);
							console.log(res.data.data.yuleData);
							this[refArray[0].show] = true;
							this.isLoading = false;
							if(this.curentIndex>1)
								this.stick.updateSticky();
						}
						
					}else{
						let layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}	
							
				})
			},
			handleScroll:function(){
				
				// 滚动的高度
				let scrollTop = document.documentElement.scrollTop||window.pageYOffset||document.body.scrollTop;
				// 屏幕的高度
				let clientHeight = document.documentElement.clientHeight;
				// 
				let scrollHeight = document.documentElement.scrollHeight;
				if(scrollHeight-scrollTop-clientHeight<=55&&this.isScroll){
					this.isScroll = false;
					this.curentIndex +=1;
					if(this.curentIndex>this.pagecount)
						return;
					this.getYuleList()
				}		
			},
			goDetail:function(yuid){
				this.$router.push({name:'yuleinfo' , params:{id:yuid+".html"}})
			},
		}
	}
</script>

<style>
	@import url("../assets/css/base.css");
	@import url("../assets/css/header.css");
	@import url("../assets/css/main.css");
</style>
