<template>
	<div  class="links-box ">
		<ul>
			<li v-for="linkInfo,index in linksList" :key="index">
				<a :href="linkInfo.url" target="_blank" :title="linkInfo.title">{{linkInfo.title}}</a>
			</li>
			<li>
				<a href="/link" target="_blank" title="申请交换友情链接地址"><span style="color: #FF0000;">申请友链</span></a>
			</li>
		</ul>
		<div class="clear"></div>
	</div>
</template>

<script>
	export default{
		data:function(){
			return {
				linksList :[]
			}
		},
		created:function(){
			let headerData = {
					headers : {
						source : 1,
						vcode  : "11"
					}
				}
			// 获取友情链接
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/links',headerData).then(res=>{
				if(res.data.code == 200){
					this.linksList = res.data.data;
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
		}
	}
</script>

<style>
</style>
