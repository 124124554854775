<template>
	<div class="sidebar__inner" style="max-height: 2343px;overflow: hidden;">
		<!-- 顶部个人信息 -->
		<div class="catwget-box catwget animate__animated animate__fadeInDown" id="catblog-box">
			<div class="author-top">
				<div class="user-avatar">
					<img alt="飞猫个人博客" src="../assets/images/user-logo.png" class="avatar-photo" title="飞猫">
				</div>
				<div class="user-info">
					<span class="names">
						<a href="" title="飞猫博客" rel="author">飞猫博客</a>
					</span>
					<span class="cattag" title="飞猫博客">博 主</span>
					<span class="cat-desc">
						飞猫博客，记录个人工作中所遇到问题，欢迎大家常来做客!!! 您的支持是我最大的动力!
					</span>
				</div>
			</div>
			<div class="sidebar_author_btn">
				<div class="sab_btn sidebar_zan" @click="addlike()">
					<i></i>
					<span title="觉得本站不错赞一个吧！"  id="likecount">{{datainfo.ilikeCount}}</span>
				</div>
				<div class="sab_btn sidebar_guanzhu">
					<i class="iconfont icon-jiahao1"></i>
					<span title="扫码联系我">
						联系我
					</span>
					<div class="qrcode slidetop">
						<img alt="微信号"  src="../assets/images/erweima.png">
						<span class="triangle"></span>
					</div>
				</div>
			</div>
			<div class="cat-author">
				<span class="num-info pn">浏览 {{datainfo.totalViewCount}} 次</span>
				<span class="num-info pn" >
					运行 <a id="days" title=""> {{datainfo.day}} </a>天
				</span>
				<span class="num-info" >
					发布 <a id="art-count" title="">{{datainfo.articleCount}}</a> 篇</span>
			</div>
		</div>
		<!-- 热门文章 -->
		<div ref="sliderArt" class="catwget-box catwget" v-if="hotlist.length>0" >
			<h3 :class="{animate__fadeInDown:sliderArtShow}" class="catget-title animate__animated">
				<i class="fa fa-th iconfont icon-remen"></i>热门文章
			</h3>
			<div :class="{animate__fadeInDown:sliderArtShow}" id="con_one" class="animate__animated">
				<ul id="con_one_1" style="display:block" >
					<li v-for="hotInfo,index in hotlist" :key="index">
						<span class="li-icon" :class="hotInfo.classname">{{index+1}}</span>
						<a @click="goDetail(hotInfo.id+'.html')" style="cursor: pointer;" :title="hotInfo.title">{{hotInfo.title}}</a>
					</li>
				</ul>
			</div>
		</div>
		<!-- 百度广告 -->
		<div ref="sliderArt" :class="{animate__fadeInDown:sliderArtShow}" class="catwget-box catwget animate__animated" >
			<BaiduAdv></BaiduAdv>
		</div>
		<!-- 阅读榜单 -->
		<div ref="news" class="catwget-box catwget wow" v-if="readMoreList.length>0" id="slide-new-publish">
			<h3 :class="{animate__fadeInDown:newsShow}" class="catget-title animate__animated">
				<i class="fa fa-th iconfont icon-zuixin"></i>阅读榜单
			</h3>
			<ul :class="{animate__fadeInDown:newsShow}" class="catwget-content slide-new-publish animate__animated">
				<li class="clr" v-for="rectInfo , index in readMoreList" :key="index">
					<a @click="goDetail(rectInfo.id+'.html')" style="cursor: pointer;" :title="rectInfo.title">
						<div class="time">
							<span class="r">{{rectInfo.viewcount}}</span>
						</div>
						<div class="title">{{rectInfo.title}}</div>
					</a>
				</li>
			</ul>
		</div>
		<!-- 评论最多 -->
		<div ref="comments" class="catwget-box catwget wow " v-if="commentslist.length>0" id="articele-comment">
			<h3 :class="{animate__fadeInDown:commentsShow}" class="catget-title animate__animated">
				<i class="fa fa-th iconfont icon-pinglun1"></i>热门评论
			</h3>
			<ul :class="{animate__fadeInDown:commentsShow}" class="widget-content article-comment-ul animate__animated">
				<li v-for="commInfo,index in commentslist" :key="index">
					<a @click="goDetail(commInfo.id+'.html')" style="cursor: pointer;" :title="commInfo.title">
						<div class="comment-img">
							<img :src="commInfo.pic" class="randsimg s">
						</div>
						<div class="comment-left">
							<span class="comment-title">{{commInfo.title}}</span>
							<span class="comment-clock">
								<i class="fa iconfont icon-time"></i> {{commInfo.updatetime}}
							</span>
							<span class="comment-info">
								<i class="fa iconfont icon-pinglun1"></i> {{commInfo.commentcount}}
							</span>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<!-- 最新留言 -->
		<div ref="guest" class="catwget-box catwget wow" v-if="gestbooklist.length>0" id="slide-new-guestbook">
			<h3 :class="{animate__fadeInDown:guestShow}" class="catget-title animate__animated">
				<i class="fa fa-th iconfont icon-liuyanban-05"></i>最新留言
			</h3>
			<ul :class="{animate__fadeInDown:guestShow}" class="catwget-content slide-new-guestbook animate__animated">
				<li v-for="gestInfo ,index in gestbooklist" :key="index">
					<span class="zb_avatar">
						<a href="#" style="cursor: pointer;">
							<img :src="gestInfo.userphoto">
							<i title="访客" class="author-ident author6"></i>
						</a>
					</span>
					<p>
						<a>{{gestInfo.content}}</a>
					</p>
					<small>{{gestInfo.username}} 评论于：{{gestInfo.addtime}}</small>
				</li>
			</ul>
		</div>
		<!-- 热门标签 -->
		<div ref = "tag" class="catwget-box catwget wow tag-div" v-if="taglist.length>0" id="slide-tag">
			<h3 :class="{animate__fadeInDown:tagShow}"  class="catget-title animate__animated">
				<i class="fa fa-th iconfont icon-biaoqian"></i>热门标签
			</h3>
			<ul :class="{animate__fadeInDown:tagShow}" class="catwget-content slide-tag animate__animated">
				<li class="divTags8" v-for="tagInfo,index in taglist" :key="index">
					<a  @click="goArticle(tagInfo.name1+'.html')" :title="tagInfo.name">{{tagInfo.name}}
						<span class="tag-count"> ({{tagInfo.count}})</span>
					</a>
				</li>
			</ul>
		</div>		
		<div style="height: 2343px;"></div>
	</div>
</template>

<script>
	import BaiduAdv from "./BaiduAdv.vue";
	export default{
		components:{
			BaiduAdv
		},
		data:function(){
			return {
				hotlist       : [],
				taglist       : [],
				readMoreList  : [],
				commentslist  : [],
				gestbooklist  : [],
				datainfo      : {},
				sliderArtShow : false,
				newsShow      : false,
				commentsShow  : false,
				guestShow     : false,
				tagShow       : false
			}
		},
		mounted:function(){

			window.addEventListener("scroll", this.handleScroll);
			
			let infoData = {
					headers : {
						source : 1,
						vcode  : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/cinfo',infoData).then(res=>{
				if(res.data.code == 200){
					this.datainfo = res.data.data;
				}
			})
			
			let headerData = {
					headers : {
						size   : 9,
						source : 1,
						vcode  : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/hots',headerData).then(res=>{
				if(res.data.code == 200){
					this.hotlist = res.data.data;
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
			// 热门标签
			let tagData = {
					headers : {
						size   : 15,
						source : 1,
						vcode  : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/tags',tagData).then(res=>{
				if(res.data.code == 200){
					this.taglist = res.data.data;
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
			let readMoreData = {
					headers : {
						size   : 4,
						source : 1,
						vcode  : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/readmore',readMoreData).then(res=>{
				if(res.data.code == 200){
					this.readMoreList = res.data.data;
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
			// 获取最多评论文章
			let commentsData = {
					headers : {
						size   : 4,
						source : 1,
						vcode  : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/commarts',commentsData).then(res=>{
				if(res.data.code == 200){
					this.commentslist = res.data.data;
					console.log(this.commentslist);
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
			
			// 获取最新留言
			let gestbookData = {
					headers : {
						size   : 3,
						source : 1,
						vcode  : "11"
					}
				}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/indexgests',gestbookData).then(res=>{
				if(res.data.code == 200){
					this.gestbooklist = res.data.data;
					console.log(this.gestbooklist);
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
		},
		methods:{
			handleScroll:function(){
				let refArray = [
					{ref: 'sliderArt', show: 'sliderArtShow'},
					{ref: 'news', show: 'newsShow'},
					{ref: 'comments', show: 'commentsShow'},
					{ref: 'guest', show: 'guestShow'},
					{ref: 'tag', show: 'tagShow'}
				]
				for(let i =0 ;i<refArray.length;i++){
					let dom = this.$refs[refArray[i].ref]
					let offset = 20;
					if(dom){
						var a,b,c,d;
						d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
						a = eval(d + offset)
						b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
						c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
						if(b + c > a){
							this[refArray[i].show] = true;
						}
					}
				}
			},
			goArticle:function(tagName){
				this.$router.push({path:'tagart', query:{tag: tagName}})
			},
			goDetail:function(artid){
				this.$router.push({name:'artdetail' , params:{id:artid}})
			},
			addlike:function(){
				let data = {
						relativeid  : 0,
						type        : 1,
						source      : 1,
						vcode       : "11"
					};
				let param = this.qs.stringify(data);
				
				this.axios.post(process.env.VUE_APP_API_PREFIEX+"/api/like",param).then(res=>{
					var layerid ; 
					if(res.data.code == '200'){
						this.datainfo.ilikeCount  = res.data.data.likecount;
						layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}else{
						layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}
				});
			}
		}
	}
</script>

<style>
	@import url("../assets/css/main.css");
</style>

