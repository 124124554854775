<template>
	<ol class="commentlist">
		<div ref="commentslist" class="comment-tab">
			<div class="come-comt">
				<i class="fa fa-comments"></i>评论列表 
				<span id="comment_count">(已有
					<span style="color:#E1171B">{{commentsCount}}</span>条评论)
				</span>
			</div>
		</div>
		<div v-if="commentsCount>0">
			<div class="shadow-box msg noimg wow" v-for="commentInfo,index in commentsList" :key="index"  >
					<div class="msgimg">
						<img class="avatar" :src="commentInfo.userphoto" >
					</div>
					<div class="msgtxt">
						<div class="msgname">
							<a>{{commentInfo.nickname}}</a>
							<span class="LevelName aulevel6">游客</span>
							<span>{{commentInfo.addtime}}
								<a @click="replyComments(commentInfo.id)" class="comment-reply-link">回复</a>
							</span>
						</div>
						<div class="msgarticle">{{commentInfo.comment}}
							<div :ref="'reply_'+commentInfo.id" style="display: none;">
								<CommentTool v-bind:commentData="commentInfo" @change-comments="change"></CommentTool>
							</div> 
							<div class="shadow-box msg noimg wow"  v-for="replyInfo,index in commentInfo.replyData" :key="index">
								<div class="msgimg">
									<img class="avatar" :src="replyInfo.userphoto" >
								</div>
								<div class="msgtxt">
									<div class="msgname">
										<a href=""  target="_blank">{{replyInfo.nickname}}</a>
										<span class="LevelName aulevel6">游客</span>
										<span>{{replyInfo.addtime}}</span>
									</div>
									<div class="msgarticle">
										{{replyInfo.comment}}
									</div>
								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
		<div class="no-comments" v-else>暂无评论</div>
		<!--评论输出结束-->
	</ol>
</template>

<script>
	import CommentTool from "../components/CommentTool.vue";
	export default{
		props:['commentData'],
		components:{
			CommentTool
		},
		data:function(){
			return {
				relativeid    : this.commentData.relativeid,
				type          : this.commentData.type,
				commentsCount : this.commentData.commentsCount,
				commentsList : this.commentData.commentsList
			}
		},
		mounted:function(){
		},
		methods:{
			change:function(data , commentid){
				this.commentsCount = data.commentsCount;
				this.commentsList = data.comentsData;
				console.log(this.commentsList);
				this.$refs['reply_'+commentid][0].style.display="none";
			},
			replyComments:function(id){
				
				for(var key in this.$refs){
					if(Array.isArray((this.$refs[key])))
						this.$refs[key][0].style.display="none";
				}
				let el =this.$refs['reply_'+id][0];
				el.style.display="block";
			}
		}
	}
</script>

<style>
</style>
