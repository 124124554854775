<template>
	<div class="_u2ynr97a88i"></div>
</template>



<script>
	export default{
		mounted:function(){
			(window.slotbydup = window.slotbydup || []).push({
				id: "u6598607",
				container: "_u2ynr97a88i",
				async: true
			});	
		}
	}
</script>

<style>
</style>
