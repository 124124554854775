<template>
	<div id="comt-respond" class="commentpost wow fadeInDown" style="visibility: visible; animation-name: fadeInDown;">
		<h4>
			<i class="fa fa-pencil"></i>发表评论
			<span>
				<a rel="nofollow" id="cancel-reply" href="#comment" style="display:none;">
					<small>取消回复</small>
				</a>
			</span>
		</h4>
		<div id="frmSumbit" target="_self" method="post">
			<div class="comt-box">
				<div class="form-group liuyan form-name">
					<input ref="relativeid" type="hidden"  v-model="relativeid" />
					<input ref="commentid" type="hidden"  v-model="commentid" />
					<input type="text" id="inpName" class="text" name="inpName" v-model="nickname"  placeholder="昵称">
				</div>
				<div class="form-group liuyan form-email">
					<input type="text" id="inpEmail" class="text" name="inpEmail"  v-model="email" placeholder="邮箱">
				</div>
			</div>
			<!--verify-->
			<div id="comment-tools">
				<span class="com-title">快捷回复：</span>
				<a @click="addNumber('文章不错,写的很好，继续加油哦！')" style="cursor: pointer;" title="文章不错,写的很好，继续加油哦！">
					<i class="fa fa-thumbs-o-up iconfont icon-dianzan "></i>
				</a>
				<a @click="addNumber('我踩！我踩踩踩！我使劲踩！')"  style="cursor: pointer;" title="我踩！我踩踩踩! 我使劲踩！">
					<i class="fa fa-thumbs-o-down iconfont icon-cai"></i>
				</a>
				<a @click="addNumber('支持一下！不能骄傲哦，要继续努力！')" style="cursor: pointer;" title="支持一下！不能骄傲哦，要继续努力！">
					<i class="fa fa-heart iconfont icon-zan "></i>
				</a>
			</div>
			<textarea v-model="comment" placeholder="和谐网络 文明上网 理性发言！O(∩_∩)O~~" name="txaArticle" id="txaArticle" class="text input-block-level comt-area" cols="50" rows="4" tabindex="5" onkeydown="if(event.ctrlKey&amp;&amp;event.keyCode==13){document.getElementById('submit').click();return false};"></textarea>
			<p>
				<input name="sumbit" type="button" value="提交" @click="addComment()" class="button">
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		props:['commentData'],
		data:function(){
			return {
				relativeid : this.commentData.relativeid,
				commentid : this.commentData.id, 
				type      : this.commentData.type,
				nickname  : '',
				email     : '',
				comment   : ''
			}
		},

		methods:{
			addNumber:function(info){
				this.comment = info;
			},
			addComment:function(){
				let data = {
						relativeid : this.commentData.relativeid,
						commentid  : this.commentData.id, 
						type       : this.commentData.type,
						nickname   : this.nickname,
						email      : this.email,
						comment    : this.comment,
						source     : 1,
						vcode      : "11"
					};
				let param = this.qs.stringify(data);
				
				this.axios.post(process.env.VUE_APP_API_PREFIEX+"/api/artcoms",param).then(res=>{
					var layerid ; 
					if(res.data.code == '200'){
						layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
							console.log(res.data.data);
							this.$emit("change-comments" , res.data.data , this.commentid);
							this.nickname  = '';
							this.email     = '';
							this.comment   = '';
						});
					}else{
						layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}
				});
			}
		}
	}
</script>

<style>
</style>
