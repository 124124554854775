<template>
	<div class="content" >
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading" class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left" ref="note">	
				<div  class="rec-title new-publish animate__animated animate__fadeInDown" style="margin-top: 0;">每周日志</div>
				<article  class="post_box animate__animated animate__fadeInDown"  v-for="noteInfo,index in noteList" :key="index">
					<div class="post-img note-img col-xs-4">
						<a @click="goDetail(noteInfo.id)"  style="display: block;zoom:1;cursor: pointer;">
							<img class="img-responsive img-rounded imgs" :src="noteInfo.pic"  :title="noteInfo.title"><em></em>
						</a>
					</div>
					<div class="post-left">
						<h3>
							<a @click="goDetail(noteInfo.id)"  :title="noteInfo.title">{{noteInfo.title}}</a>
						</h3>
						<aside class="item-meta note-meta">
							<span class="mu-ml">
								<i class="fa fa-list-alt"></i>{{noteInfo.author}}
							</span>
							<span class="mu-ml-clock">
								<i class="fa fa-calendar"></i>{{noteInfo.addtime}}
							</span>
							<span class="mu-ml-eye">
								<i class="fa fa-paw"></i>{{noteInfo.viewcount}}次浏览
							</span>
						</aside>
					</div>
				</article>
			</div>
			<!-- <div class="clear"></div> -->
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	import StickySidebar from "sticky-sidebar";
	import Loading from "../components/Loading.vue";
	export default{
		components:{
			SlideBar,
			Loading
		},
		data:function(){
			return {
				noteList:[],
				isScroll : true,
				isLoading :true,
				limit:20,
				curentIndex:1,
				notshow:false,
				pagecount:0,
				stick:{}
			}
		},
		created:function(){
			this.getNoteList()
			window.addEventListener("scroll", this.handleScroll);
		},
		mounted:function(){
			setTimeout(() => {
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});	
			}, 2000);
			
		},
		destroyed:function(){
			window.removeEventListener('scroll',this.handleScroll)
		},
		methods:{
			getNoteList:function(){
				let refArray = [
					{ref: 'note', show: 'notshow'},
				]
				// 获取精选推荐
				let recommData = {
					headers : {
						size     : this.limit,
						source   : 1,
						vcode    : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/notelists?page='+this.curentIndex,recommData).then(res=>{					
					if(res.data.code == 200){
						this.isLoading = false;
						this.pagecount = res.data.data.pagecount;
						if(res.data.data.notesData.length>0){
							this.noteList = this.noteList.concat(res.data.data.notesData);
							this[refArray[0].show] = true;
							this.isLoading = false;
							if(this.curentIndex>1)
								this.stick.updateSticky();
						}
						
					}else{
						let layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}	
							
				})
			},
			handleScroll:function(){
				
				// 滚动的高度
				let scrollTop = document.documentElement.scrollTop||window.pageYOffset||document.body.scrollTop;
				// 屏幕的高度
				let clientHeight = document.documentElement.clientHeight;
				// 
				let scrollHeight = document.documentElement.scrollHeight;
				if(scrollHeight-scrollTop-clientHeight<=55&&this.isScroll){
					this.isScroll = false;
					this.curentIndex +=1;
					if(this.curentIndex>this.pagecount)
						return;
					this.getNoteList()
				}		
			},
			goDetail:function(noteid){
				this.$router.push({name:'noteinfo' , params:{id:noteid+".html"}})
			},
		}
	}
</script>

<style>
	@import url("../assets/css/base.css");
	@import url("../assets/css/header.css");
	@import url("../assets/css/main.css");
</style>
