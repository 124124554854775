import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible'
import animate from 'animate.css'
import Axios from "axios";
import Qs from "qs";
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)


// highlight.js代码高亮插件
import Highlight from './directive/highlight'; // 这里是你项目highlight.js所在路径
Vue.use(Highlight);

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;



Vue.prototype.$layer = layer(Vue);
Vue.config.productionTip = false
Vue.use(animate);
Vue.prototype.axios = Axios;
Vue.prototype.qs = Qs;
router.beforeEach((to, from, next) => {
	// //百度统计
	if (to.path) {
		if (window._hmt) {
			window._hmt.push(['_trackPageview', '/#' + to.fullPath])
		}
	}
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
});
new Vue({
	router,
	/* 这句非常重要，否则预渲染将不会启动 */
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	},
	render: h => h(App),
}).$mount('#app')
