<template>
	<div class="content">
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading"  class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left" ref="article">				
				<div  class="rec-title new-publish" style="margin-top: 0;">{{catname}}</div>
				<BaiduAdvTwo></BaiduAdvTwo>
				<article :class="{animate__fadeInDown:artshow}"  class="post_box animate__animated"  v-for="artInfo,index in artList" :key="index">
					<div class="post-img col-xs-4">
						<a @click="goDetail(artInfo.id)"  style="display: block;zoom:1;cursor: pointer;">
							<img class="img-responsive img-rounded imgs" :src="artInfo.pic"  :title="artInfo.title"><em></em>
						</a>
					</div>
					<div class="post-left">
						<h3>
							<a @click="goDetail(artInfo.id)" style="cursor: pointer;"  :title="artInfo.title">{{artInfo.title}}</a>
						</h3>
						<div class="post-con">
							<a @click="goDetail(artInfo.id)" style="cursor: pointer;" >{{artInfo.desc}}</a>
						</div>
						<aside class="item-meta">
							<span class="mu-ml">
								<i class="fa  iconfont icon-chuangzuozhezhongxin"></i>{{artInfo.author}}
							</span>
							<span class="mu-ml-clock">
								<i class="fa  iconfont icon-time"></i>{{artInfo.updatetime}}
							</span>
							<span class="mu-ml-eye">
								<i class="fa iconfont icon-liulanliang"></i>{{artInfo.viewcount}}次浏览
							</span>
							<span class="mu-ml-comment">
								<i class="fa iconfont icon-pinglun1"></i>{{artInfo.commentcount}}条评论
							</span>
						</aside>
					</div>
				</article>
			</div>
			<!-- <div class="clear"></div> -->
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	import StickySidebar from "sticky-sidebar";
	import Loading from "../components/Loading.vue";
	import BaiduAdvTwo from "../components/BaiduAdvTwo.vue";
	export default{
		components:{
			SlideBar,
			Loading,
			BaiduAdvTwo
		},
		data:function(){
			return {
				isScroll : true,
				curentIndex:1,
				limit :14 ,
				catid : 0,
				artList   : [],
				catname    : '',
				artshow:false,
				isLoading :true,
				pagecount:0,
				stick:{}
			}
		},
		created:function(){
			// ok方式
			this.catid = this.$route.query.catid.slice(0,-5);
			this.getArtList();
			window.addEventListener("scroll", this.handleScroll);
		},
		mounted:function(){
			setTimeout(() => {
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});	
			}, 2000);
		},
		destroyed:function(){
			window.removeEventListener('scroll',this.handleScroll)
		},
		beforeRouteUpdate:function(to , from ,next){
			// ok方式
			if(to.query.catid == undefined)
				return;
			this.catid = to.query.catid.slice(0,-5);
			this.artList = [];
			this.isScroll = true;
			this.curentIndex = 1;
			this.artshow = false;
			if(this.catid)
				this.getArtList();
			next();	
		},
		methods:{
			goDetail:function(artid){
				this.$router.push({name:'artdetail' , params:{id:artid+".html"}})
			},
			handleScroll:function(){
				// 滚动的高度
				let scrollTop = document.documentElement.scrollTop||window.pageYOffset||document.body.scrollTop;
				// 屏幕的高度
				let clientHeight = document.documentElement.clientHeight;
				// 
				let scrollHeight = document.documentElement.scrollHeight;
				if(scrollHeight-scrollTop-clientHeight<=55&&this.isScroll){
					this.isScroll = false;
					this.curentIndex +=1;
					if(this.curentIndex>this.pagecount)
						return;
					this.getArtList()
				}		
				
			},
			getArtList:function(){
				let refArray = [
					{ref: 'article', show: 'artshow'},
				]
				// 获取精选推荐
				let recommData = {
					headers : {
						size     : this.limit,
						catid    : this.catid,
						source   : 1,
						vcode    : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/artlist?page='+this.curentIndex,recommData).then(res=>{					
					if(res.data.code == 200){
						this.isLoading = false;
						this.pagecount = res.data.data.pagecount;
						if(res.data.data.articleData.length>0){
							this.isScroll = true;
							if(this.curentIndex == 1){
								this.catname    = res.data.data.catname;
							}
							this.artList = this.artList.concat(res.data.data.articleData);
							this[refArray[0].show] = true;
							if(this.curentIndex>1)
								this.stick.updateSticky();
						}
					}else{
						let layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}	
						
				})
			}
		}
	}
</script>

<style>
	@import url("../assets/css/main.css");
</style>
