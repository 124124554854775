<template>
	<div class="_rt5nvc39lj"></div>
</template>



<script>
	export default{
		mounted:function(){
			(window.slotbydup = window.slotbydup || []).push({
				id: "u6570118",
				container: "_rt5nvc39lj",
				async: true
			});		
		}
	}
</script>

<style>
</style>
