<template>
	<div class="content" v-wechat-title="catname">
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading"  class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left" ref="article">				
				<div  class="rec-title new-publish" style="margin-top: 0;">{{catname}}</div>
				<BaiduAdvTwo></BaiduAdvTwo>
				<article :class="{animate__fadeInDown:artshow}"  class="post_box animate__animated"  v-for="artInfo,index in artList" :key="index">
					<div class="post-img col-xs-4">
						<a @click="goDetail(artInfo.id)"  style="display: block;zoom:1;cursor: pointer;">
							<img class="img-responsive img-rounded imgs" :src="artInfo.pic"  :title="artInfo.title"><em></em>
						</a>
					</div>
					<div class="post-left">
						<h3>
							<a @click="goDetail(artInfo.id)"  :title="artInfo.title">{{artInfo.title}}</a>
						</h3>
						<div class="post-con">
							<a @click="goDetail(artInfo.id)" >{{artInfo.desc}}</a>
						</div>
						<aside class="item-meta">
							<span class="mu-ml">
								<i class="fa  iconfont icon-chuangzuozhezhongxin"></i>{{artInfo.author}}
							</span>
							<span class="mu-ml-clock">
								<i class="fa  iconfont icon-time"></i>{{artInfo.updatetime}}
							</span>
							<span class="mu-ml-eye">
								<i class="fa iconfont icon-liulanliang"></i>{{artInfo.viewcount}}次浏览
							</span>
							<span class="mu-ml-comment">
								<i class="fa iconfont icon-pinglun1"></i>0条评论
							</span>
						</aside>
					</div>
				</article>
				<div v-if="nodatashow"  class="nosearch-data">{{nodata}}</div>
			</div>
			<!-- <div class="clear"></div> -->
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	import StickySidebar from "sticky-sidebar";
	import Loading from "../components/Loading.vue";
	import BaiduAdvTwo from "../components/BaiduAdvTwo.vue";
	export default{
		components:{
			SlideBar,
			Loading,
			BaiduAdvTwo
		},
		data:function(){
			return {
				title : '',
				artList   : [],
				catname    : '',
				nodata     : '',
				nodatashow : false,
				artshow:false,
				isLoading :true,
				stick:{}
			}
		},
		created:function(){
			// ok方式
			this.title = this.$route.params.title.slice(0, -5);
			this.getArtList();
		},
		mounted:function(){

			setTimeout(() => {
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});	
			}, 2000);
		},
		beforeRouteUpdate:function(to , from ,next){
			// ok方式
			if(to.params.title == undefined)
				return;
			this.title = to.params.title.slice(0, -5);
			this.artList = [];
			this.artshow = false;
			if(this.title)
				this.getArtList();
			next();	
		},
		methods:{
			goDetail:function(artid){
				this.$router.push({name:'artdetail' , params:{id:artid+".html"}})
			},
			getArtList:function(){
				let refArray = [
					{ref: 'article', show: 'artshow'},
				]
				// 获取精选推荐
				let recommData = {
					headers : {
						words    : this.title,
						source   : 1,
						vcode    : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/search',recommData).then(res=>{					
					if(res.data.code == 200){
						this.isLoading = false;
						this.nodatashow = false;
						if(res.data.data.articleData.length>0){
							this.catname    = res.data.data.keywords+" "+res.data.data.articleData.length+"篇" ;
							this.title      = res.data.data.keywords;
							this.artList    = res.data.data.articleData;
						}
					}else{
						this.isLoading = false;
						this.nodatashow = true;
						this.nodata = res.data.msg;
						// let layerid = this.$layer.msg(res.data.msg,()=>{
						// 	this.$layer.close(layerid);
						// });
					}
						
				})
			}
		}
	}
</script>

<style>
	@import url("../assets/css/main.css");
	
	.nosearch-data{
		height: 400px;
		line-height: 400px;
		text-align: center;
		font-size: 26px;
		color: #cccccc;
	}
</style>
