<template>
	<div class="content">
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading"  class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left">	
				<div class="widget-box post-page">
					<header id="post-header">
						<div class="post-meta">
							<span class="time">{{contentData.addtime}}</span>
							<span class="eye">
								<i class="fa fa-eye"></i>{{contentData.viewcount}}
							</span>
						</div>
						<h2 class="post-title">
							<a href="javascript:void(0)" title="正在阅读：关于" rel="bookmark">留言板</a>
						</h2>
					</header>
					<div class="entry  animate__animated animate__fadeInDown">
						<div class="cc-grade-a-des  guestbook-div">
							<p class="guestbook-p">
								<span class="gestbook-title" >留言注意事项：</span><br>
							</p>
							<p class="guestbook-p">
								<strong>一、</strong>严禁发布或传播不良信息等内容。
							</p>
							<p class="guestbook-p">
								<strong>二、</strong>相互尊重，并对自己的言论和行为负责。
							</p>
							<p class="guestbook-p">
								<strong>三</strong>
								<strong>、</strong>尊重网络道德，严禁发表侮辱、诽谤、教唆、淫秽的内容。
							</p>
							<p class="guestbook-p">
								<strong>
									<span style="color: #FF0000;">四、</span>
								</strong>
								<span style="color: #FF0000;">本站有权保留、引用或删除不合理的留言或评论，并不做任何通知或提醒。</span>
							</p>
							<p class="guestbook-p">
								<strong>五</strong>
								<strong>、</strong>参与留言或评论，即表明您已阅读并接受上述条款，如您对此有异议，你可终止操作。
							</p>
							<p class="guestbook-p">
								<strong>六</strong>
								<strong>、</strong>如有任何问题，请发邮件联系博主：ddklsy@163.com
							</p>
						</div>
					</div>
					<div  ref="sharetool" :class="{animate__fadeInDown:shareShow}"  class="xf_zsf animate__animated">
						<LikeShareTool v-bind:likeInfo="likeData" v-if="likeData.finish" ></LikeShareTool>
					</div>
					<div ref="authorarea" :class="{animate__fadeInDown:authorShow}"  class="animate__animated">
						<AuthorArea v-bind:articleCount = "artCount"></AuthorArea>
					</div>
					<section    class="comments">
						<!--评论框-->
						<div ref="coomentss" :class="{animate__fadeInDown:commentsdata}"  class="animate__animated" >
							<GuestBookTool  v-bind:guestbookid="gestBookData.pid" @change-gestbooks="change">	
							</GuestBookTool>
						</div>
						<!--评论框结束-->
						<div ref="commentslist" :class="{animate__fadeInDown:commentlistShow}"  class="animate__animated" >
							<GuestBooks v-bind:gestBooksData = "gestBookData" v-bind:key="gestBookData.gestbookCount"></GuestBooks>
						</div>
						<!--评论输出-->
					</section>
				</div>
			</div>
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	import AuthorArea from "../components/AuthorArea.vue";
	import GuestBookTool from "../components/GuestBookTool.vue";
	import GuestBooks from "../components/GuestBooks.vue";
	import LikeShareTool from "../components/LikeShareTool.vue";
	import StickySidebar from "sticky-sidebar";
	import Loading from "../components/Loading.vue";
	export default{
		components:{
			SlideBar,
			GuestBookTool,
			GuestBooks,
			AuthorArea,
			LikeShareTool,
			Loading
		},
		data:function(){
			return {
				contentData: {},
				artCount:0,
				isLoading :true,
				shareShow:false,
				authorShow:false,
				commentsdata:false,
				commentlistShow:false,
				gestBookData:{pid:0},
				likeData:{relativeid:0,count:0 , type :5 , finish:false},
				refArray : [
					{ref:'sharetool' , show:'shareShow'},
					{ref:'authorarea' , show:'authorShow'},
					{ref:'coomentss' , show:'commentsdata'},
					{ref:'commentslist' , show:'commentlistShow'}
				],
				stick:{}
			}
		},
		created:function(){
			this.getInfo('2');
			this.getGuestBooks();
			window.addEventListener("scroll", this.handleScroll);
		},
		mounted:function(){
			setTimeout(() => {
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});	
			}, 3000);
		},
		methods:{
			change:function(data , guestbookid){
				this.gestBookData.gestbookData  = data.gestbookData;
				this.gestBookData.gestbookCount = data.gestbookCount;
				this.$forceUpdate();
				this.stick.updateSticky();
			},
			handleScroll:function(){
				console.log(this.$refs);
				for(let i =0 ;i<this.refArray.length;i++){
					let dom = this.$refs[this.refArray[i].ref]
					let offset = 20;
					if(dom){
						var a,b,c,d;
						d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
						a = eval(d + offset)
						b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
						c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
						if(b + c > a){
							this[this.refArray[i].show] = true;
						}
					}
				}
			},
			getInfo:function(type){
				let code = this.$md5(this.$md5(type)+this.$md5('1'));
				
				let artData = {
					headers : {
						type     : type,
						source   : 1,
						vcode    : code
					}
				}
				
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/systems',artData).then(res=>{
					if(res.data.code == 200){
						this.isLoading = false;
						this.contentData = res.data.data.sysData;
						this.artCount    = res.data.data.artCount;
						this.likeData.relativeid = this.contentData.id;
						this.likeData.count = this.contentData.likecount;
						this.likeData.finish = true;
					}else{
						// 如果获取数据失败，跳转到首页
						this.$router.push("/");
					}
				})
			},
			getGuestBooks:function(){
				let comData = {
					headers : {
						size        : 10,
						source      : 1,
						vcode       : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/gestbooks',comData).then(res=>{
					if(res.data.code == 200){
						this.gestBookData.gestbookData = res.data.data.gestbookData;
						this.gestBookData.gestbookCount = res.data.data.gestbookCount;
					}else{
						let layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}	
				})
			},
		}
	}
</script>

<style>
	
</style>
