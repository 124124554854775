<template>
	<div class="loading"></div>
</template>

<script>
    export default {
        name: "loading"
    }
</script>

<style scoped>
    .loading {
		margin: 150px auto;
        background: url('../assets/images/loading.gif') center center no-repeat ;
        width: 50vw;
        height: 50vh;
        z-index: 1000;
    }

</style>