import Vue from 'vue'
import VueRouter from 'vue-router'
import CodeTepl from "../views/CodeTepl.vue";
import Yule from "../views/Yule.vue";
import Index from "../views/Index.vue";
import Article from "../views/Article.vue";
import TagArticle from "../views/TagArticle.vue";
import Guestbook from "../views/Guestbook.vue";
import ArticleDetail from "../views/ArticleDetail.vue";
import About from "../views/About.vue";
import Link from "../views/Link.vue";
import Notes from "../views/Notes.vue";
import YuleDetail from "../views/YuleDetail.vue";
import NoteDetail from "../views/NoteDetail.vue";
import Share from "../views/Share.vue";
import CodeDetail from "../views/CodeDetil.vue";
import Search from "../views/Search.vue";
import Notfound from "../views/Notfound.vue";
import Demo from "../views/Demo.vue";
Vue.use(VueRouter)


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
	{
		path:"/",
		component:Index,
		meta:{
			title: '飞猫个人博客',
			content: 'disable'
		}
	},
	{
		path:"/article",
		name:'article',
		component:Article,
		meta:{
			title: '文章列表 -- 飞猫个人博客',
			content: 'disable'
		}
	},
	{
		path:"/artdetail/:id",
		name:'artdetail',
		component:ArticleDetail
	},
	{
		path:"/code.html",
		component:CodeTepl,
		meta:{
			title: '网站源码 -- 飞猫个人博客',
			content: 'disable'
		}
	},
	{
		path:"/yule.html",
		component:Yule,
		meta:{
			title: '开心一刻 -- 飞猫个人博客',
			content: 'disable'
		}
	},
	{
		path:"/guestbook.html",
		component:Guestbook,
		meta:{
			title: '留言板 -- 飞猫个人博客',
			content: 'disable'
		}
	},
	{
		path:"/about.html",
		component:About,
		meta:{
			title: '关于我们 -- 飞猫个人博客',
			content: 'disable'
		}
	},
	{
		path:"/link.html",
		component:Link,
		meta:{
			title: '友情链接 -- 飞猫个人博客',
			content: 'disable'
		}
	},
	{
		path    : "/tagart",
		name    : 'tagart',
		component:TagArticle
	},
	{
		path    : "/notes.html",
		component:Notes,
		meta:{
			title: '每周日志 -- 飞猫个人博客',
			content: 'disable'
		}
	},
	{
		path    : "/yuleinfo/:id",
		name    : 'yuleinfo',
		component:YuleDetail
	},
	{
		path    : "/noteinfo/:id",
		name     : 'noteinfo',
		component:NoteDetail
	},
	{
		path    : "/share.html",
		component : Share
	},
	{
		path    : '/codeinfo/:id',
		name    : 'codeinfo',
		component:CodeDetail
	},
	{
		path    : '/search/:title',
		name    : 'search',
		component : Search
	},
	{
		path: '*',
		name: 'notfound.html',
		component: Notfound,
		meta:{
			title: '糟糕！服务器开小差了~~ -- 飞猫个人博客',
			content: 'disable'
		}
	}
]



const router = new VueRouter({
  routes,
  scrollBehavior(to, from,savedPosition) {
	return {
		x: 0,
		y: 0
	}
  },
  mode:'history'
})

export default router
