<template>
	<div class="zanshang-content">
		<div class="zanshang-way" >
			<label for="wechat"><input @change="changeWay(0)" type="radio" id="wechat" class="reward-radio" value="0" name="reward-way" checked="checked">微信</label>
			<label for="alipay"><input @change="changeWay(1)"  type="radio" id="alipay" class="reward-radio" value="1" name="reward-way">支付宝</label>
		</div>
		<div class="zanshang-img">
			<div class="qrcode-img qrCode_0" v-if="wxpay" id="qrCode_0">
				<div class="pay-border" style="border: 9.02px solid #59DA99">
					<img class="qrcode-img qrCode_0" id="qrCode_0" src="../assets/images/wx.png">
				</div>
				<p class="qrcode-tip">扫码打赏，你说多少就多少</p>
			</div>
			<div class="qrcode-img qrCode_2" v-if="alipay" id="qrCode_1">
				<div class="pay-border" style="border: 9.02px solid #59DA99">
					<img class="qrcode-img qrCode_2" id="qrCode_2" src="../assets/images/alpay.png">
				</div>
				<p class="qrcode-tip">扫码打赏，你说多少就多少</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data:function(){
			return {
				alipay:false,
				wxpay:true,
				payway: 0
			}
		},
		methods:{
			changeWay:function(data){
				if(data == 1){
					this.alipay = true;
					this.wxpay = false;
				}else{
					this.alipay = false;
					this.wxpay = true;
				}
			}
		}
	}
</script>

<style>
</style>
