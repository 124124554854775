<template>
	<div class="bottom">
		<div class="bottom-content">
			<ul class="bottom-ul">
				<li @click="goLink('yule')" style="cursor: pointer;">开心一刻</li>
				<li @click="goLink('notes')" style="cursor: pointer;">每周日志</li>
				<li @click="goLink('about')" style="cursor: pointer;">关于我们</li>
				<li @click="goMap('https://icomplaints.cn/sitemap.xml')" style="cursor: pointer;">站点地图</li>
				<li @click="goLink('link')" style="cursor: pointer;">申请友链</li>
			</ul>
			<div class="bottom-copyright">
				<span>Copyright2019-2021. Email:ddklsy@163.com 本站基于ThinkPHP搭建. 京ICP备2021016156号-1</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		methods:{
			goLink:function(link){
				this.$router.push({path:link});
			},
			goMap:function(url){
				window.open(url,'_blank');

			}
		}
	}
</script>

<style>
</style>
