<template>
	<transition name="back-to-top">
		<div class="icon-plus" @click="goTop()" v-if="isShow" id="toTop">Back to Top</div>
	</transition>
</template>

<script>
	export default{
		data:function(){
			return {
				isShow:false
			}
		},
		created:function(){
			window.addEventListener("scroll", this.handleScroll);
		},
		methods:{
			handleScroll:function(){
				let scrollTop = document.documentElement.scrollTop||window.pageYOffset||document.body.scrollTop;
				if (scrollTop >= 700) {
					this.isShow = true;
				} else {
					this.isShow = false;
				}
			},
			goTop:function(){
				var timer = setInterval(function(){
					let osTop = document.documentElement.scrollTop||window.pageYOffset||document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5); 
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
					if(osTop === 0){
						clearInterval(timer);
					}
				},30)
			}
		},

	}
</script>

<style>
	#toTop {
		width: 47px;
		height: 47px;
		background: url(../assets/images/back-top-btn.png) no-repeat;
		text-align: center;
		position: fixed;
		bottom: 125px;
		right: 20px;
		cursor: pointer;
		text-indent: -9999px;
		z-index: 12;
	}
	
	.back-to-top-enter-active{
		transition: opacity .5s;
	}
	.back-to-top-enter{
		opacity: 0;
	}
	.back-to-top-leave-active{
		transition: opacity .5s;
	}
	.back-to-top-leave-to{
		opacity: 0;
	}

</style>
