<template>
	<div class="div_nabbar" >
		<ul class="ul-navbar">
			<router-link  tag="li" class="header-li" to="/" >首页</router-link>
			<router-link  tag="li"  class="header-li" to="#"  >
				技术博客
				<ul class="gustbook-ul article-ul  animate__animated animate__fadeInDown">
					<li class="gustbook-li"  v-for="cat , index in catlist" :key="index" @click="goCatArt(cat.id)">{{cat.name}}</li>
				</ul>
			</router-link>
			<router-link  tag="li" class="header-li" to="/code.html" >网站源码</router-link>
			<router-link  tag="li" class="header-li" to="/notes.html" >每周日志</router-link>
			<router-link  tag="li" class="header-li" to="/yule.html" >开心一刻</router-link>
			<router-link  tag="li" class="header-li" to="##" >
				<span style="display: inline-block;height: 48px;overflow: hidden;">关于+留言</span>
				<ul class="gustbook-ul animate__animated animate__fadeInDown">
					<router-link tag="li" to="/guestbook.html" class="gustbook-li">留言</router-link>
					<router-link tag="li" to="/link.html" class="gustbook-li">友情链接</router-link>
					<router-link tag="li" to="/about.html" class="gustbook-li">关于我们</router-link>
				</ul>
			</router-link>
			<li class=" header-li li-search " >
				<div class="div-search">
					<input type="text" class="search" v-model="keywords" @keyup.enter="searchArt()" placeholder="搜一下又不会怀孕">
					<img @click="searchArt()"  class="img-search" type="image" src="../assets/images/search.png" />
				</div>
			</li>
		</ul>
		<div class="clear"></div>
	</div>
</template>

<script>
	export default{
		data:function(){
			return {
				catlist : [],
				keywords : ''
			}
		},
		created:function(){
			let headerData = {
					headers : {
						source : 1,
						vcode  : "11"
				}
			}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/cats',headerData).then(res=>{
				if(res.data.code == 200){
					this.catlist = res.data.data;
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}
					
			})
		},
		methods:{
			goCatArt :function(id){
				// ok方式
				this.$router.push({path:'article' , query:{catid:id+".html"}})
			},
			searchArt : function(){
				if(this.keywords == '' || this.keywords == undefined || this.keywords == "undefined"){
					let layerid = this.$layer.msg('关键词不能为空',()=>{
						this.$layer.close(layerid);
					});
				}else{
					this.$router.push({name:'search' , params:{title:encodeURIComponent(this.keywords)+".html"}})
				}
			}
		}

	}
</script>

<style>
	@import url("../assets/css/fonts/iconfont.css");
</style>
