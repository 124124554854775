<template>
	<div class="xf_zsf-main">
		<span class="likes">
			<a href="javascript:;" @click="addlike()" title="文章写得很好，必须赞一个！" class="sf-praise-sdk">
				<i class="iconfont icon-zan"></i> 赞 
				<span class="sf-praise-sdk"  >{{count}}</span>
			</a>
		</span>
		<span class="da_shang">
			<a @click="pay()" style="cursor: pointer;" >赏</a>
		</span>
		<span class="shares">
			<a @click="share()" style="cursor: pointer;"  title="文章不错，好内容要一起分享。">
				<i class="iconfont icon-fenxiang"></i> 分享 
			</a>
		</span>
		<div class="clear"></div>
	</div>
</template>

<script>
	
	import Share from "../views/Share.vue";
	import Pay from "../views/Pay.vue";
	export default {
		props:['likeInfo'],
		data:function(){
			return {
				relativeid    : this.likeInfo.relativeid,
				type          : this.likeInfo.type,
				count         : this.likeInfo.count
			}
		},
		methods:{
			share:function(){
				this.$layer.iframe({
					content: {
						content: Share, //传递的组件对象
						parent: this,//当前的vue对象
						data:{
							relativeid : this.relativeid,
							type       : this.type
						}//props
					},
					area:['360px','400px'],
					title: '请选择分享方式'
				});
			},
			pay:function(){
				this.$layer.iframe({
					content: {
						content: Pay, //传递的组件对象
						parent: this,//当前的vue对象
						data:{
						}//props
					},
					area:['360px','400px'],
					title: '请选择赞赏方式'
				});
			},
			addlike:function(){
				let data = {
						relativeid  : this.relativeid,
						type        : this.type,
						source      : 1,
						vcode       : "11"
					};
				let param = this.qs.stringify(data);

				this.axios.post(process.env.VUE_APP_API_PREFIEX+"/api/like",param).then(res=>{
					var layerid ; 
					if(res.data.code == '200'){
						this.count  = res.data.data.likecount;
						layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}else{
						layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}
				});
			}
		}
	}
</script>

<style>
</style>
