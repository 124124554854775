<template>
	<div class="qrcode-img">
		<div class="four-share">
			<a :href="shareInfo.xinlangShareUrl" title="分享到新浪微博" class="xf_weibo" target="_blank">
				<span class="share-span"></span>
			</a>
			<a :href="shareInfo.qqShareUrl" title="分享给QQ好友" class="xf_qqqq" target="_blank">
				<span class="share-span"></span>
			</a>
			<a :href="shareInfo.qqZoneShareUrl" title="分享到QQ空间" class="xf_qzone" target="_blank">
				<span class="share-span"></span>
			</a>
		</div>
		<div id="output" class="qrcode-border box-size" ref="qrCodeUrl">
		</div>
		<div class="wechat-icon"></div>
		<p class="qrcode-tip">微信扫一扫，分享朋友圈</p>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'

	export default{
		props:['relativeid', 'type'],
		data:function(){
			return {
				shareInfo: {}
			}
		},
		created:function(){
			let recommData = {
				headers : {
					relativeid     : this.relativeid,
					type           : this.type,
					source         : 1,
					vcode          : "11"
				}
			}
			this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/likeinfo',recommData).then(res=>{					
				if(res.data.code == 200){
					this.shareInfo = res.data.data;
					this.creatQrCode(res.data.data.wexinShareUrl);
				}else{
					let layerid = this.$layer.msg(res.data.msg,()=>{
						this.$layer.close(layerid);
					});
				}	
					
			})
		},
		methods:{
			creatQrCode:function(shareUrl){
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: shareUrl, // 需要转换为二维码的内容
					width: 200,
					height: 200,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
		}
		
	}
</script>

<style>
	@import url("../assets/css/main.css");
	

</style>
