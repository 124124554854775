<template>
	<div class="content">
		<transition name="fade">
			<Loading v-if="isLoading"></Loading>
		</transition>
		<div v-if="!isLoading" class="middle-content">
			<div id="sidebar" class="index-content-right">
				<SlideBar></SlideBar>
			</div>
			<div class="index-content-left" ref="ele">	
				<div class="widget-box post-page">
					<article class="widget-content single-post">
						<header id="post-header">
							<div class="post-meta">
								<span class="time">{{contentData.addtime}}</span>
								<span class="eye">
									<i class="fa fa-eye"></i>{{contentData.viewcount}}
								</span>
							</div>
							<h2 class="post-title">
								<a href="javascript:void(0)" title="正在阅读：关于" rel="bookmark">关于飞猫</a>
							</h2>
							<div class="clear"></div>
						</header>
						<div class="entry animate__animated animate__fadeInDown">
							<p class="aboutinfo-nickname " >
								一个记录
								<span style="color:#59DA99">生活</span>与
								<span style="color:#59DA99">分享编程技术</span>的
								<span style="color:#59DA99">个人博客</span>
							</p>
							<p class="aboutinfo-location ">
								据点：中国.北京.西城
							</p>
							<hr class="more">
							<p>
								<span style="color: #FF0000;">
									<strong style="font-size: 16px;"><br></strong>
								</span>
							</p>
							<p>
								<span style="color: #59DA99;">
									<strong style="font-size: 20px;">博主信息</strong>
								</span><br>
							</p>
							<p class="about-p">
								博主：<a target="_blank" href="/" style="color:#59DA99">飞猫博客</a>&nbsp;
							</p>
							<p class="about-p">
								<span style="text-indent: 2em;">性别：你猜 &nbsp;&nbsp;</span>
							</p>
							<p class="about-p">
								<span style="text-indent: 2em;">年龄：10后</span>
							</p>
							<p class="about-p">
								职业：假程序员 &nbsp; &nbsp; &nbsp;
								<span style="text-indent: 2em;">据点：中国北京</span>
							</p>
							<p class="about-p">
								据点：中国北京
							</p>
							<p class="about-p">
								简介：我是一个假程序员，正在通往真程序员的道路上前进。
							</p>
							<p class="about-p">
								初衷：记录生活的点点滴滴，分享工作中遇到的问题，帮助更多人解决问题。
							</p>
							<h1 style="margin: 25px 0px 0px; padding: 0px; text-align: left; font-size: 16px; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" id="listree-list0">
								<p>
									<span style="color: #59DA99; font-size: 20px;">
										<strong>个人介绍</strong>
									</span>
								</p>
							</h1>
							<p class="about-p">
								<p class="about-p">本人是全栈工程师，拥有多你那开发经验，熟悉前端，PHP，小程序，可以熟练使用开发中常用的框架。目前，在单位主要负责前端的开发，业余时间负责个人博客的改版和维护。</p>
								<p class="about-p">飞猫个人博客从上线至今，已经经历了两次大改版。</p>
								<p class="about-p">本次改版涉及内容非常多，后台使用ThinkPHP 6 ，前端使用Vue Cli进行开发，做到前后分离。</p>
								<p class="about-p">此站点只作者本人开发，难免有不足之处！如果大家发现问题，请大家及时联系博主，期待大家的留言哦！</p>
							</p>
							<h1 style="margin: 25px 0px 0px; padding: 0px; text-align: left; font-size: 16px; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" id="listree-list1">
								<p>
									<span style="color: #59DA99; font-size: 20px;">
										<strong>网站介绍</strong>
									</span>
								</p>
							</h1>
							<p class="about-p">
								<p class="about-p">此站点主要用于记录开发中遇到的各种问题，记录解决方案，方便以后查找。同时，也为了分享知识，帮助更多需要的人。</p>
								
								<p class="about-p">关于内容：本站点下内容均为博主原创。如需转载，请注明来源。</p>
								
								<p class="about-p">关于友链：如需添加友情链接，请留言或者添加微信联系！</p>
							</p>
							<h1 style="margin: 25px 0px 0px; padding: 0px; text-align: left; font-size: 16px; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" id="listree-list2">
								<p>
									<span style="color: #59DA99; font-size: 20px;">
										<strong>联络方式</strong>
									</span>
								</p>
							</h1>
							<p class="about-p">
								邮件：ddklsy@163.com
							</p>
						</div>
						<div id="" ref="sharetool" :class="{animate__fadeInDown:shareShow}"  class=" xf_zsf animate__animated">
							<LikeShareTool v-bind:likeInfo="likeData" v-if="likeData.finish" ></LikeShareTool>
						</div>
						<div ref="authorarea" :class="{animate__fadeInDown:authorShow}"  class="animate__animated">
							<AuthorArea v-bind:articleCount = "artCount"></AuthorArea>
						</div>
					
						
						<div class="nav-links">
							<div class="nav-previous">
								<a href="" rel="prev">
									<span class="meta-nav-r">
										<i class="fa fa-angle-left"></i>
										</span>
								</a>
							</div>
							<div class="nav-next">
								<a href="" rel="next">
									<span class="meta-nav-l">
										<i class="fa fa-angle-right"></i>
									</span>
								</a>
							</div>
						</div>
						<section   class="comments">
							<!--评论框-->
							<div ref="coomentss" :class="{animate__fadeInDown:commentsdata}"  class="animate__animated" >
								<CommentTool v-bind:commentData="CommentData" @change-comments="change">
								</CommentTool>
							</div>
							<!--评论框结束-->
							<div ref="commentslist" :class="{animate__fadeInDown:commentlistShow}"  class="animate__animated" >
								<Comments v-bind:commentData="CommentData" v-bind:key="CommentData.commentsCount"></Comments>
							</div>
							<!--评论输出-->
						</section>
					</article>
				</div>
			</div> 
			<div class="clear"></div>
		</div>
		<div class="clear"></div>
	</div>
</template>

<script>
	import SlideBar from "../components/SlideBar.vue";
	import CommentTool from "../components/CommentTool.vue";
	import Comments from "../components/Comments.vue";
	import AuthorArea from "../components/AuthorArea.vue";
	import LikeShareTool from "../components/LikeShareTool.vue";
	import StickySidebar from "sticky-sidebar";
	import Loading from "../components/Loading.vue";
	export default{
		components:{
			SlideBar,	
			CommentTool,
			Comments,
			AuthorArea,
			LikeShareTool,
			Loading
		},
		data:function(){
			// tempCommentData  type:1:文章评论 2：网站源码评论 3：开心一刻评论 4：关于我们评论   99：留言
			return {
				contentData: {},
				CommentData:{relativeid:0 , id:0, type :4},
				likeData:{relativeid:0,count:0 , type :7 , finish:false},
				artCount:0,
				shareShow:false,
				authorShow:false,
				commentsdata:false,
				commentlistShow:false,
				isLoading :true,
				refArray : [
					{ref:'sharetool' , show:'shareShow'},
					{ref:'authorarea' , show:'authorShow'},
					{ref:'coomentss' , show:'commentsdata'},
					{ref:'commentslist' , show:'commentlistShow'}
				],
				stick:{}
			}
		},
		created:function(){
			this.getInfo('1');
			this.getComments();
		},
		mounted:function(){
			window.addEventListener("scroll", this.handleScroll);
			setTimeout(function(){
				this.stick = new StickySidebar('#sidebar', {
					topSpacing: 20,
					bottomSpacing: 20,
					containerSelector: '.middle-content',
					innerWrapperSelector: '.sidebar__inner'
				});		
			}, 3000)
		},
		methods:{
			change:function(data , commentid){
				this.CommentData.commentsList  = data.comentsData;
				this.CommentData.commentsCount = data.commentsCount;
				this.$forceUpdate();
				this.stick.updateSticky();
			},
			handleScroll:function(){
				console.log(this.$refs);
				for(let i =0 ;i<this.refArray.length;i++){
					let dom = this.$refs[this.refArray[i].ref]
					let offset = 20;
					if(dom){
						var a,b,c,d;
						d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
						a = eval(d + offset)
						b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
						c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
						if(b + c > a){
							this[this.refArray[i].show] = true;
						}
					}
				}
			},
			getInfo:function(type){
				let code = this.$md5(this.$md5(type)+this.$md5('1'));
				let artData = {
					headers : {
						type     : type,
						source   : 1,
						vcode    : code
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/systems',artData).then(res=>{
					if(res.data.code == 200){
						this.isLoading = false;
						this.contentData = res.data.data.sysData;
						this.CommentData.relativeid = this.contentData.id;
						this.artCount    = res.data.data.artCount;
						this.likeData.relativeid = this.contentData.id;
						this.likeData.count = this.contentData.likecount;
						this.likeData.finish = true;
					}else{
						// 如果获取数据失败，跳转到首页
						this.$router.push("/");
					}
				})
			},
			getComments:function(){
				let comData = {
					headers : {
						relativeid  : this.CommentData.relativeid,
						type        : this.CommentData.type,
						source      : 1,
						vcode       : "11"
					}
				}
				this.axios.get(process.env.VUE_APP_API_PREFIEX+'/api/artcoms',comData).then(res=>{
					if(res.data.code == 200){
						this.CommentData.commentsList = res.data.data.comentsData;
						this.CommentData.commentsCount = res.data.data.commentsCount;
						this.stick.updateSticky();
					}else{
						let layerid = this.$layer.msg(res.data.msg,()=>{
							this.$layer.close(layerid);
						});
					}	
				})
			},
		}
	}
</script>

<style>
	
</style>
